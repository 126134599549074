<template>
  <div class="pulsa position-relative">
    <div class="container p-4">
      <div class="d-flex justify-content-between align-items-center">
        <router-link to="/main" class="text-white" style="text-shadow: 0 0 4px rgba(0,0,0, .3);text-decoration: none;"><i class="bi bi-arrow-left-circle-fill me-2"></i>Kembali</router-link>
      </div>
    </div>
  </div>
  <div class="container gap-fix-1 p-4">
    <h3>Topup Voucher.</h3>
    <div>
      
      <!-- <h3>Top Up Voucher.</h3> -->
      <!-- <div v-if="row_status != 1"> -->
      <div v-if="true">
        <div class="mb-3">
          <label for="isi" class="form-label">Nominal Topup Voucher.</label>
          <input type="tel" class="form-control" id="isi" v-model.number="transferAmount" placeholder="Masukan Nominal">
          <small v-if="errorMessage" class="text-danger">{{ errorMessage }}</small>
        </div>
        <a v-if="qris != ''" class="btn mb-5 btn-primary w-100 btn-lg" :href="qris">Continue Convert</a>
        <button type="button" class="btn mb-5 btn-primary w-100 btn-lg" @click="handleSubmit" id="btn">Convert</button>
        <!-- <button type="button" class="btn mb-5 btn-primary w-100 btn-lg" id="btn">Bayar</button> -->
        <!-- <div class="row mb-3">
          <div class="col-12">
            <div class="p-3 card">
              <div class="row">
                <div class="col-6">
                  <small>Masa Tenggang <i class="fa-solid fa-ticket ms-2 text-primary"></i></small>
                </div>
                <div class="col-6">
                  <small>
                    5% dari jumlah TV
                  </small>
                </div>
              </div>
              <hr/>
              <small style="font-size: 9px;">
                Masa tengang paling telat di hari kamis, periksa tanggal mu jangan sampai coin habis sia-sia tanpa sisa.
              </small>
              <div class="row mt-3">
                <div class="col-6">
                  <h6 class="m-0 p-0">TV (Token Value)</h6>
                  <h6 class="fw-light">{{ formatRupiah(rpt) }}</h6>
                </div>
                <div class="col-6">
                  <h6 class="m-0 p-0">Harga Period</h6>
                  <h6 class="fw-light">{{ formatRupiah(rpt * 0.05) }}</h6>
                </div>
              </div>
              <button v-if="row_status == 0" class="btn btn-primary mt-3 mb-2" @click="buyVoucherByVoucher">Bayar Dengan Saldo Voucher</button>
              <button v-if="row_status == 0" class="btn btn-outline-primary" @click="buyVoucherBySaldo">Bayar Dengan Saldo Cash</button>
              <button v-if="row_status == 1" class="btn btn-secondary mt-3 mb-2">Bayar Dengan Saldo Voucher</button>
              <button v-if="row_status == 1" class="btn btn-outline-secondary">Bayar Dengan Saldo Cash</button>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <div class="p-3 card">
              <div class="row">
                <div class="col-6">
                  <small>UV (Unit Value) <i class="fa-solid fa-piggy-bank ms-2 text-primary"></i></small>
                </div>
                <div class="col-6">
                  <small>
                    Harga Rp 60.000
                  </small>
                </div>
              </div>
              <hr/>
              <small style="font-size: 9px;">
                Beli UV setiap bulannya agar bisa mendapatkan energi
              </small>
              <button v-if="boar_status == 0" class="btn btn-primary mt-3 mb-2" @click="buyBoarByVoucher">Bayar Dengan Saldo  Voucher</button>
              <button v-if="boar_status == 1" class="btn btn-secondary mt-3 mb-2">Bayar Dengan Saldo Voucher</button>
              <button v-if="boar_status == 0" class="btn btn-outline-primary" @click="buyBoarBySaldo">Bayar Dengan Saldo Cash</button>
              <button v-if="boar_status == 1" class="btn btn-outline-secondary">Bayar Dengan Saldo Cash</button>
            </div>
          </div>
        </div> -->
      </div>
      <div v-else>
        <p class="text-center mb-">Perpanjangan masa berlaku hanya di hari kamis</p>
        <button type="button" class="btn btn-secondary w-100 btn-lg">Belum Bisa Bayar</button>
      </div>  
      <!-- <div class="card mb-3 p-3 border-info bg-info-subtle position-relative">
        <small class="text-info">Sumber Dana.</small>
        <h6 class="m-0 p-0">Saldo Cash Saya.</h6>
        <h3 class="m-0 p-0">{{ formatRupiah(saldo) }}</h3>
        <i class="bi bi-cash-stack position-absolute text-info" style="bottom: -10px;right: 10px;font-size: 64px;"></i>
      </div> -->
      <div class="card mb-3 p-3 border-info bg-info-subtle position-relative">
        <small class="text-info">Pricing.</small>
        <h6>UV (Unit Value) <!--<i class="fa-solid fa-piggy-bank ms-2 text-primary"></i>--></h6>
        <small>
          Harga Rp 60.000
        </small>
        <hr>       
        <h6 class="m-0 p-0">TV (Token Value)</h6>
        <h6 class="fw-light">{{ formatRupiah(rpt) }}</h6>
        <h6 class="m-0 p-0">Harga Period</h6>
        <h6 class="fw-light">{{ formatRupiah(rpt * 0.05) }}</h6>
        <i class="fa-solid fa-coins position-absolute text-info" style="bottom: 10px;right: 10px;font-size: 54px;"></i>
      </div>
      <div class="card mb-3 p-3 border-warning bg-warning-subtle position-relative">
        <small class="text-warning">Voucher.</small>
        <h6 class="m-0 p-0">Saldo Voucher.</h6>
        <h3 class="m-0 p-0">{{ formatRupiah(voucher) }}</h3>
        <i class="fa-solid fa-ticket position-absolute text-warning" style="top: 40px;right: 10px;font-size: 54px;"></i>
        <!-- <br>
        <form>
          <input type="tel" class="form-control" id="isi" placeholder="Masukan Code">
          <button class="btn btn-warning w-100 mt-2">Claim Voucher</button>
        </form>
        <button class="btn btn-warning w-100 mt-2" data-bs-toggle="modal" data-bs-target="#modal">Generate Code Voucher</button> -->
        <br>
        <router-link to="/tfv" class="btn btn-warning w-100">Transfer Voucher</router-link>
      </div>
      <!-- Modal -->
      <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Generate Voucher</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <input type="tel" class="form-control" id="isi" placeholder="Masukan Nominal">
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Generate</button>
            </div>
          </div>
        </div>
      </div>    
    </div>
  </div>
  <div class="d-none justify-content-center flex-column align-items-center bg-dark" id="load" style="opacity: .95;z-index: 999;position: fixed;top: 0;left: 0;right: 0;bottom: 0;">
    <div class="spinner-border text-white" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <small class="text-white mt-2">Loading...</small> 
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

export default {
  name: 'TFView',
  data() {
    return {
      saldo: 0,
      point_1: 0,
      rpt: 0,
      rptValue: 0,
      voucher: 0,
      token: "",
      customerId: "",
      name: "",
      qris: "",
      paymentFee: [],
      transferAmount: 0,
      selectedPaymentMethod: "SP",
      errorMessage: "",
      merchantOrderId: "",
      transactionStatus: null,
      orderId: "",
      row_status: 0,
      boar_status: 0,
    };
  },
  mounted() {
    const merchantOrderId = this.$route.query.merchantOrderId;
    if(merchantOrderId != null) {
      document.querySelector("#load").classList.add("d-flex")
      document.querySelector("#load").classList.remove("d-none")
      axios.post("https://api.tokoku.org/public/api/duitku/transaction-status", {
        merchantOrderId: merchantOrderId
      })
      .then((res) => {
        document.querySelector("#load").classList.remove("d-flex")
        document.querySelector("#load").classList.add("d-none")
        if(res.data.statusMessage == "SUCCESS"){          
          axios.patch(`https://api.tokoku.org/public/api/customers/${customerId}/voucher`, {
            voucher: parseInt(res.data.amount) + parseInt(this.voucher)
          }, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          })
            .then(() => {
              axios.post(`https://api.tokoku.org/public/api/riwayat`, {
                trx : 'voucher',
                status : 1,
                row_status : 1,
                actor_1 : this.customerId,
                actor_2 : null,
                actor_3 : null,
                value : res.data.amount,
              })
              .then(() => {
                this.$router.push('/voucher')
              })
              .catch((error) => {
                console.error(error);
              });
            })

          document.querySelector("#load").classList.remove("d-flex")
          document.querySelector("#load").classList.add("d-none")
  
          this.voucher = parseInt(res.data.amount) + parseInt(this.voucher)
        }
        console.log("Transaction Status:", res.data);
      })
      .catch((error) => {
        document.querySelector("#load").classList.remove("d-flex")
        document.querySelector("#load").classList.add("d-none")
        this.errorMessage = "Gagal memeriksa status transaksi.";
        console.error(error);
      });
    }

    const token = Cookies.get('auth_token');
    const customerId = Cookies.get('customer_id');

    if (!token || !customerId) {
      this.token = "";
      this.customerId = "";
      this.$router.push("/");
    } else {
      this.customerId = customerId;
      this.token = token;
      axios
        .get("https://api.tokoku.org/public/api/customers/" + customerId, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.pin = response.data.customer.pin;
          this.voucher = 0;          
          this.name = response.data.customer.name;
          this.saldo = 0;
          this.rpt = response.data.receive_tv_details.point;
          this.point_1 = response.data.receive_tv_details.point;
          this.row_status = 0;
          this.boar_status = 0;
        });
    }
  },
  methods: {
    buyVoucherByVoucher(){
      console.log(this.voucher);
      console.log(this.point_1);
      console.log(parseInt(this.point_1 * 0.05));      
      
      if(this.voucher >= parseInt(this.point_1 * 0.05)){
        // axios.patch(`https://api.tokoku.org/public/api/customers/${this.customerId}/voucher`, {
        //       voucher: this.voucher - (this.point_1 * 0.05)
        //     }, {
        //       headers: {
        //         Authorization: `Bearer ${this.token}`,
        //       },
        //     })
        axios.post(`https://api.tokoku.org/public/api/transaksi`, {
                trx : 'period',
                status : 1,
                row_status : 1,
                actor_1 : this.customerId,
                actor_2 : null,
                actor_3 : null,
                value : this.point_1 * 0.05,
              })
              .then(() => {
                axios.patch(`https://api.tokoku.org/public/api/customers/${this.customerId}/row-status`, {
                  row_status: 0
                }, {
                  headers: {
                    Authorization: `Bearer ${this.token}`,
                  },
                })
                  .then(() => {
                    axios.post(`https://api.tokoku.org/public/api/riwayat`, {
                      trx : 'period',
                      status : 1,
                      row_status : 1,
                      actor_1 : this.customerId,
                      actor_2 : null,
                      actor_3 : null,
                      value : this.point_1 * 0.05,
                    })
                    .then(() => {
                      this.$router.push('/voucher')
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                  })
              })
      }else {
        Swal.fire({
          icon: 'error',
          title: 'Saldo Tidak Cukup!'
        })
      }
    },
    buyVoucherBySaldo(){
      if(this.saldo >= (this.point_1 * 0.05)){
        axios.patch(`https://api.tokoku.org/public/api/customers/${this.customerId}/saldo`, {
              saldo: this.saldo - (this.point_1 * 0.05)
            }, {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            })
              .then(() => {
                axios.patch(`https://api.tokoku.org/public/api/customers/${this.customerId}/row-status`, {
                  row_status: 1
                }, {
                  headers: {
                    Authorization: `Bearer ${this.token}`,
                  },
                })
                  .then(() => {
                    axios.post(`https://api.tokoku.org/public/api/riwayat`, {
                      trx : 'period',
                      status : 1,
                      row_status : 1,
                      actor_1 : this.customerId,
                      actor_2 : null,
                      actor_3 : null,
                      value : this.point_1 * 0.05,
                    })
                    .then(() => {
                      this.$router.push('/voucher')
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                  })
              })
      }else {
        Swal.fire({
          icon: 'error',
          title: 'Saldo Tidak Cukup!'
        })
      }
    },
    buyBoarByVoucher(){
      console.log(this.voucher);
      
      if(this.voucher >= 60000){
        // axios.patch(`https://api.tokoku.org/public/api/customers/${this.customerId}/voucher`, {
        //       voucher: this.voucher - 60000
        //     }, {
        //       headers: {
        //         Authorization: `Bearer ${this.token}`,
        //       },
        //     })        
        axios.post(`https://api.tokoku.org/public/api/transaksi`, {
                trx : 'tv',
                status : 1,
                row_status : 1,
                actor_1 : this.customerId,
                actor_2 : null,
                actor_3 : null,
                value : 60000,
              })
              .then(() => {
                axios.patch(`https://api.tokoku.org/public/api/customers/${this.customerId}/boar-status`, {
                  boar_status: 0
                }, {
                  headers: {
                    Authorization: `Bearer ${this.token}`,
                  },
                })
                  .then(() => {
                    axios.post(`https://api.tokoku.org/public/api/riwayat`, {
                      trx : 'tv',
                      status : 1,
                      row_status : 1,
                      actor_1 : this.customerId,
                      actor_2 : null,
                      actor_3 : null,
                      value : 60000,
                    })
                    .then(() => {
                      this.$router.push('/voucher')
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                  })
              })
      }else {
        Swal.fire({
          icon: 'error',
          title: 'Saldo Tidak Cukup!'
        })
      }
    },
    buyBoarBySaldo(){
      if(this.saldo >= 60000){
        axios.patch(`https://api.tokoku.org/public/api/customers/${this.customerId}/saldo`, {
              saldo: this.saldo - 60000
            }, {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            })
              .then(() => {
                axios.patch(`https://api.tokoku.org/public/api/customers/${this.customerId}/boar-status`, {
                  row_status: 1
                }, {
                  headers: {
                    Authorization: `Bearer ${this.token}`,
                  },
                })
                  .then(() => {
                    axios.post(`https://api.tokoku.org/public/api/riwayat`, {
                      trx : 'tv',
                      status : 1,
                      row_status : 1,
                      actor_1 : this.customerId,
                      actor_2 : null,
                      actor_3 : null,
                      value : 60000,
                    })
                    .then(() => {
                      this.$router.push('/voucher')
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                  })
              })
      }else {
        Swal.fire({
          icon: 'error',
          title: 'Saldo Tidak Cukup!'
        })
      } 
    },
    getPayment(){         
      document.querySelector("#btn").innerHTML = `
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>`
      
      axios.post("https://api.tokoku.org/public/api/duitku/payment-methods", {
        amount: this.transferAmount + 2500
      })
        .then((res) => {
          console.log(res.data.paymentFee);
          this.errorMessage = "";
          this.inquiry();
        })
        .catch((error) => {
          this.errorMessage = "Gagal memuat metode pembayaran.";
          console.error(error);
        });
    },
    inquiry() {      
      document.querySelector("#btn").innerHTML = `
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>`

      let orderId = `order-${new Date().toISOString().replace(/[-:.TZ]/g, '')}`;

      this.orderId = orderId;

      axios.post("https://api.tokoku.org/public/api/duitku/inquiry", {
        paymentAmount: this.transferAmount,
        paymentMethod: this.selectedPaymentMethod,
        merchantOrderId: orderId,
        productDetails: "Topup Saldo",
        additionalParam: "",
        merchantUserInfo: this.name,
        customerVaName: "test",
        email: "test@test.com",
        phoneNumber: "0888",
        itemDetails: [
          {
            name: "Topup Saldo",
            price: this.transferAmount,
            quantity: 1
          }
        ],
        customerDetail: {
          firstName: "Test",
          lastName: "Test",
          email: "test@test.com",
          phoneNumber: "08123456789",
          billingAddress: {
            firstName: "Test",
            lastName: "Test",
            address: "Jl. Testing",
            city: "Test",
            postalCode: "123",
            phone: "0888",
            countryCode: "ID"
          },
          shippingAddress: {
            firstName: "Test",
            lastName: "Test",
            address: "Jl. Testing",
            city: "Test",
            postalCode: "123",
            phone: "0888",
            countryCode: "ID"
          }
        },
        callbackUrl: "http://localhost:8080/voucher",
        returnUrl: "http://localhost:8080/voucher",
        // callbackUrl: "https://api.tokoku.org",
        // returnUrl: "https://tokoku.org",
        expiryPeriod: 10
      })
      .then((res) => {
        console.log("Inquiry response:", res.data);
        document.querySelector("#btn").style.display = "none"
        this.qris = res.data.paymentUrl
        Swal.fire({
          title: 'Konfirmasi Pembayaran',
          html: `
            <p>Total Pembayaran: <strong>${this.formatRupiah(this.transferAmount)}</strong>
            <br/>Admin Fee: <strong>${this.formatRupiah(2500)}</strong></p>
          `,
          icon: 'info',
        })

        setInterval(() => {
          this.checkTransactionStatus()
        }, 1000)
      })
      .catch((error) => {
        this.errorMessage = "Gagal memproses inquiry.";
        console.error(error);
      });
    },
    checkTransactionStatus() {
      axios.post("https://api.tokoku.org/public/api/duitku/transaction-status", {
        merchantOrderId: this.orderId
      })
      .then((res) => {
        this.transactionStatus = res.data;
        console.log("Transaction Status:", res.data);
      })
      .catch((error) => {
        this.errorMessage = "Gagal memeriksa status transaksi.";
        console.error(error);
      });
    },
    formatRupiah(value) {
      // return new Intl.NumberFormat('id-ID', {
      //   style: 'currency',
      //   currency: 'IDR',
      // }).format(value);       
      return new Intl.NumberFormat('id-ID', {
        useGrouping: true,
        minimumFractionDigits: 0, // Tanpa desimal
      })
        .format(value)
        .replace(/\./g, '.'); // Mengganti titik pemisah ribuan dengan spasi
    },
    handleSubmit() {
      this.errorMessage = "";
      this.getPayment();
      console.log("Nominal Transfer:", this.transferAmount);
    },
  },
};
</script>

<style>
  .pulsa {
    background-image: url(../../assets/bg.jpg);
    background-size: 110%;
    background-position: center;
    width: 100%;
    height: 80px;
    border-radius: 0 0 15px 15px;
  }
</style>