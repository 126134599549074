<template>
  <div class="react p-3">
    <div class="btn-down">
      <div class="d-flex flex-column justify-content-between h-100">
        <div>
          <!-- Menampilkan nilai UV yang sudah didapat -->
          <div class="btn btn-lg text-white fw-bold me-2">
            Unit Value
            <span class="badge custom-badge">{{ Math.floor(uvPoints) }}</span>
          </div>
          <!-- Menampilkan progress dalam persen -->
          <div class="btn btn-lg text-white fw-bold pem-span">
            Progress
            <span class="badge text custom-badge"
              >{{ progressPercentage }}%</span
            >
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-6">
              <button
                class="btn button-63 shadow me-2 w-100 custom-button"
                @click="toggleMaker"
              >
                {{ isMaking ? "Stop Maker" : "Start Maker" }}
              </button>
            </div>
            <div class="col-6">
              <router-link
                to="/minning"
                class="btn button-63 shadow w-100 custom-button1"
              >
                Back
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";

export default {
  name: "UvMaker",
  data() {
    return {
      // Nilai UV user (diambil dari server)
      uvPoints: 0,
      // Nilai progress saat ini (0 sampai maxCycle, misalnya 0 sampai 1000)
      progress: 0,
      // Status apakah proses UV Maker sedang berjalan
      isMaking: false,
      // Nilai maksimal progress (100% = 1000)
      maxCycle: 1000,
      // Total UV yang bisa didapat selama 5 jam (misalnya 1000)
      maxUV: 1000,
      // Jumlah siklus (5 menit per siklus; 5 jam = 300 menit, sehingga 60 siklus)
      totalCycles: 60,
      // Untuk keperluan demo: simulationSpeed = 1 agar butuh 5 jam nyata
      simulationSpeed: 1,
      // Penghitung siklus yang sudah selesai
      cycleCount: 0,
      // Variabel penyimpan interval
      makerInterval: null,
    };
  },
  computed: {
    // Hitung persentase progress untuk ditampilkan (0 sampai 100%)
    progressPercentage() {
      return Math.floor((this.progress / this.maxCycle) * 100);
    },
  },
  mounted() {
    // Ambil data user (UV) saat komponen dipasang
    this.fetchUserData();
  },
  methods: {
    // Ambil token dari cookies
    getAccessToken() {
      return Cookies.get("auth_token");
    },
    // Ambil customer_id dari cookies
    getCustomerId() {
      return Cookies.get("customer_id");
    },
    // Mengambil data user dari server (nilai UV saat ini)
    async fetchUserData() {
      const accessToken = this.getAccessToken();
      const customerId = this.getCustomerId();
      if (!accessToken || !customerId) {
        console.error(
          "auth_token atau customer_id tidak ditemukan di cookies!"
        );
        return;
      }
      try {
        const response = await axios.get(
          `https://api.tokoku.org/public/api/customers/${customerId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.uvPoints = response.data.receive_uv_details.point || 0;
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    // Meng-update nilai UV ke server
    async updateServerData() {
      const accessToken = this.getAccessToken();
      const customerId = this.getCustomerId();
      if (!accessToken || !customerId) return;
      try {
        await axios.patch(
          `https://api.tokoku.org/public/api/receive-uv-details/${customerId}/update-point`,
          { point: Math.floor(this.uvPoints) },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
        console.error("Error updating UV points on server:", error);
      }
    },
    // Memulai proses UV Maker
    startMaker() {
      // Jangan mulai jika UV sudah mencapai batas maksimum
      if (this.uvPoints >= this.maxUV) {
        console.warn("UV sudah mencapai batas maksimum");
        return;
      }
      this.isMaking = true;
      // Reset penghitung siklus dan progress
      this.cycleCount = 0;
      this.progress = 0;

      // Hitung increment progress tiap detik:
      // 5 menit = 300 detik, sehingga:
      // increment = maxCycle / 300 * simulationSpeed
      const increment = (this.maxCycle / 300) * this.simulationSpeed;

      // Set interval dengan update tiap 1 detik
      this.makerInterval = setInterval(() => {
        this.progress += increment;
        if (this.progress >= this.maxCycle) {
          // Satu siklus 5 menit selesai
          // Hitung UV yang ditambahkan per siklus:
          // uvIncrement = maxUV / totalCycles
          const uvIncrement = this.maxUV / this.totalCycles;
          this.uvPoints = Math.min(this.uvPoints + uvIncrement, this.maxUV);
          this.updateServerData();

          // Reset progress dan naikkan counter siklus
          this.progress = 0;
          this.cycleCount++;

          // Jika sudah mencapai total siklus (5 jam), hentikan proses maker
          if (this.cycleCount >= this.totalCycles) {
            this.stopMaker();
          }
        }
      // }, 10);
      }, 1000);
    },
    // Menghentikan proses UV Maker
    stopMaker() {
      this.isMaking = false;
      if (this.makerInterval) {
        clearInterval(this.makerInterval);
        this.makerInterval = null;
      }
      // Update data ke server saat proses dihentikan
      this.updateServerData();
    },
    // Toggle antara memulai dan menghentikan proses maker
    toggleMaker() {
      if (this.isMaking) {
        this.stopMaker();
      } else {
        // Ambil data UV terbaru sebelum memulai
        this.fetchUserData().then(() => {
          if (this.uvPoints < this.maxUV) {
            this.startMaker();
          } else {
            console.warn("UV sudah mencapai batas maksimum");
          }
        });
      }
    },
  },
  beforeUnmount() {
    if (this.makerInterval) {
      clearInterval(this.makerInterval);
    }
  },
};
// bagian yana
</script>

<style>
.react {
  width: 100%;
  height: 100dvh;
  background-image: url(../../assets/minning/rbg.gif);
  background-position: center;
  background-size: cover;
  position: relative;
}

.btn-down {
  position: absolute;
  bottom: 10px;
  top: 10px;
  left: 30px;
  right: 30px;
}

/* CSS tombol */
/* .button-63 {
  align-items: center;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 15px 24px;
  font-size: 16px !important;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
} */

.button-63:active,
.button-63:hover {
  outline: 0;
  color: white;
}

@media (min-width: 768px) {
  .button-63 {
    font-size: 24px;
    min-width: 196px;
  }
}

/* Bagian yana */
/* button kiri */
.custom-button {
  background: url("../../assets/btton.png") no-repeat center center;
  background-size: cover;
  width: 200px; /* Sesuaikan ukuran */
  height: 60px;
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 5px black;
  border: none;
  text-align: center;
  margin-bottom: 20px;
  margin-left: -50px;
}
/* button kanan */
.custom-button1 {
  background: url("../../assets/btton.png") no-repeat center center;
  background-size: cover;
  width: 200px; /* Sesuaikan ukuran */
  height: 60px;
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 5px black;
  border: none;
  text-align: center;
  margin-bottom: 20px;
  margin-left: -170px;
}
.custom-badge {
  background: url("../../assets/spanimg.png") no-repeat center center;
  background-size: cover;
  width: 150px;
  height: 50px;
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 5px black;
  border: none;
  text-align: center;
  line-height: 35px; /* Sesuaikan agar teks pas di tengah */
}

/* .pem-span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -70px;
  width: 250px;
  margin-left: 16rem;
} */
</style>
