<template>
  <div class="home d-flex align-items-center flex-column container py-3 pt-5 gap-3" style="height: 100dvh;overflow-x: hidden;background-color: #EEFCFF;">
    <img src="@/assets/logo.png" height="75px">
    <img src="@/assets/bg.png" class="w-100">
    <p  class="fw-light text-muted">PPOB (Payment Point Online Bank) is an online platform for paying bills like electricity, water, and internet.</p>
    <button class="btn btn-primary w-100 mt-3" @click="openTermCondition()">Register</button>
    <router-link to="/login" class="btn w-100 btn-outline-primary">I Already Have Account</router-link>
    <small class="d-block w-100 text-primary text-center" style="font-size: 9px;">PT INDONESIA DELAPAN PERSEN <br> CS 62 812 6555 5559</small>

    <div class="term-condition d-none">
      <div class="container pt-4">
        <button class="btn d-flex align-items-center fw-bold" @click="closeTermCondition()"><i class="bi bi-arrow-left me-2"></i>Register</button>
        <br>
        <h6 class="fw-bold">
          Selamat datang di Aplikasi RPT!<br/>
          Terima kasih telah mengunduh Aplikasi RPT.
        </h6>
        <p class="fs-term-condition fw-light mt-3" style="text-align: justify;">
          Syarat dan ketentuan ini mengatur ketentuan-ketentuan mengenai; pendaftaran untuk menjadi pengguna layanan Kami (PT Visionet Internasional), penyediaan layanan uang elektronik Kami, dan hal-hal lainnya yang berkaitan dengan Aplikasi RPT. Untuk kejelasan dan kepastian, Syarat dan Ketentuan ini berlaku sebagai perjanjian antara Anda dan Kami ("Syarat dan Ketentuan").
          PERSETUJUAN
          <br>
          <br>
          Dengan mengunduh, mengakses, melakukan pendaftaran untuk menjadi pengguna layanan Kami, menggunakan Aplikasi RPT, atau menggunakan layanan-layanan Kami berarti Anda menyatakan dan mengakui bahwa Anda telah membaca, mempelajari, memahami, dan menyetujui seluruh ketentuan-ketentuan yang diatur dalam Syarat dan Ketentuan ini. Syarat dan Ketentuan ini juga berlaku atas penggunaan layanan-layanan uang elektronik Kami melalui website dan/atau aplikasi pihak ketiga.
          <br><br>
          <strong class="fw-bold">DEFINISI</strong>
          <br><br>
          Kecuali ditentukan lain secara tegas dalam Syarat dan Ketentuan ini, istilah-istilah yang diawali dengan huruf besar dalam Syarat dan Ketentuan ini memiliki pengertian-pengertian sebagai berikut:<br>
          "Anda" adalah pihak yang merupakan Pengguna RPT ataupun pihak yang sedang melakukan pendaftaran untuk menjadi Pengguna RPT.
          "Aplikasi RPT" adalah mobile application yang dikelola oleh Kami (PT Visionet Internasional) yang digunakan untuk mengakses dan menggunakan Layanan RPT.
          "Platform Partner" adalah website dan/atau mobile application yang dikelola oleh mitra-mitra yang bekerja sama dengan Kami, yang dapat digunakan untuk mengakses dan menggunakan Layanan RPT, baik secara sebagian atau keseluruhan.<br><br>
          "Kami" atau "RPT" adalah PT Visionet Internasional yang merupakan penerbit uang elektronik berbasis server yaitu RPT Cash.
          "Layanan RPT" adalah layanan uang elektronik yang Kami sediakan sesuai dengan peraturan Bank Indonesia yang berlaku yang mengatur mengenai penyelenggaraan uang elektronik, dan layanan lainnya yang Kami sediakan di luar layanan uang elektronik.<br><br>
          "Media Komunikasi" adalah media yang digunakan dalam penyampaian informasi secara privat, yaitu; panggilan telepon, short messaging service (SMS), atau email, ke kontak Pengguna RPT yang telah didaftarkan kepada Kami.<br><br>
          "Media Publikasi" adalah media yang digunakan dalam penyampaian informasi secara publik, yaitu; publikasi pada website atau media sosial resmi RPT.<br><br>
          "Merchant" adalah penyedia barang dan/atau jasa yang dapat menerima transaksi pembayaran dengan RPT Cash, baik melalui kerja sama langsung dengan Kami, melalui kerja sama dengan mitra resmi Kami, atau melalui cara-cara yang diperbolehkan berdasarkan hukum yang berlaku. Termasuk sebagai Merchant adalah Penyedia Produk Berlangganan sebagaimana dimaksud dalam BAB tentang Transaksi Berulang (Recurring Transactions) dan penyedia Produk Keuangan sebagaimana dimaksud dalam BAB tentang Produk Keuangan.<br><br>
          "Pengguna RPT" adalah pengguna Layanan RPT yang telah memiliki akun Pengguna RPT, yang belum dinonaktifkan secara permanen, baik akun dengan klasifikasi RPT Club ataupun akun dengan klasifikasi RPT Premier.<br><br>
          "Prinsip Kehati-hatian" adalah seluruh upaya dan tindakan yang wajib dilakukan untuk mencegah terjadinya risiko-risiko keamanan dalam penggunaan Aplikasi RPT, Layanan RPT, dan/atau Produk Lainnya, yang dapat diterapkan dengan melakukan dan melaksanakan tindakan-tindakan yang diatur dalam Angka 2 pada BAB tentang Risiko Penggunaan dari Syarat dan Ketentuan ini.
          "Produk Lainnya" adalah barang dan/atau jasa selain Layanan RPT yang dapat dibeli melalui Aplikasi RPT, yang disediakan oleh pihak ketiga yang merupakan mitra Kami atau pihak yang bekerja sama dengan mitra Kami.<br><br>
          "Produk Keuangan" adalah Produk Lainnya yang merupakan produk keuangan, yaitu; asuransi, pinjaman, dan produk keuangan lainnya sebagaimana tersedia dan dapat diakses melalui Aplikasi RPT di kemudian hari.<br><br>
          "Tindakan Penyalahgunaan" adalah salah satu atau lebih dari tindakan-tindakan berikut:<br>
          Tindakan yang merupakan pelanggaran atas Syarat dan Ketentuan ini.<br><br>
          Tindakan yang merupakan pelanggaran atas peraturan perundang-undangan yang berlaku, tindakan penipuan (fraud), penggelapan, pencucian uang, pendanaan kegiatan terorisme, pembayaran atas barang dan/atau jasa ilegal, kegiatan prostitusi, atau pornografi/pornoaksi, perjudian, atau tindakan-tindakan lainnya yang merupakan pelanggaran atas kepentingan pihak manapun.
          Tindakan yang merugikan atau dapat menyebabkan kerugian kepada Kami, Pengguna RPT lainnya, Merchant, afiliasi Kami, dan/atau pihak-pihak lainnya yang bekerja sama dengan Kami.
          Tindakan yang dilakukan untuk mendapatkan keuntungan pribadi, atau dilakukan secara abusif, palsu, atau fiktif untuk mendapatkan keuntungan pribadi.<br><br>
          Tindakan yang dilakukan dengan meretas, mengelabui, menipu, dan/atau memanipulasi Aplikasi RPT, Layanan RPT, atau sistem yang Kami operasikan atau sistem yang dioperasikan oleh pihak-pihak lain yang bekerja sama dengan Kami, dan/atau afiliasi Kami.
          <br><br>
          <strong class="fw-bold">A. KETENTUAN PENDAFTARAN</strong>
          <br><br>
          Kanal Pendaftaran:<br>

          Untuk dapat mengakses dan menggunakan Layanan RPT, Anda harus terlebih dahulu melakukan pendaftaran untuk menjadi Pengguna RPT. Pendaftaran sebagaimana dimaksud dapat Anda lakukan melalui kanal pendaftaran resmi Kami, yaitu:<br>
          Aplikasi RPT;
          Platform Partner yang kami tentukan untuk dapat bertindak sebagai kanal pendaftaran elektronik resmi Kami; dan
          kanal pendaftaran resmi Kami lainnya sebagaimana Kami sediakan dan informasikan kepada Anda di kemudian hari.<br><br>
          Klasifikasi Akun:<br>

          Kami menawarkan 2 (dua) jenis klasifikasi akun keanggotaan Pe ngguna RPT, sebagai berikut:<br>
          RPT Club (Unregistered E-Money)
          RPT Club adalah klasifikasi akun Pengguna RPT yang merupakan pengguna uang elektronik unregistered sebagaimana diatur dalam Peraturan Bank Indonesia yang mengatur mengenai penyelenggaraan uang elektronik.<br><br>
          Jika Anda berhasil melakukan pendaftaran dengan menggunakan nomor ponsel dan alamat email maka Anda akan langsung menjadi Pengguna RPT dengan klasifikasi akun RPT Club.
          Pengguna RPT dengan klasifikasi RPT Club dapat menggunakan beberapa layanan uang elektronik secara terbatas, yaitu:<br>
          akun Anda dapat menampung saldo RPT Cash dengan batas maksimum Rp2.000.000,- (dua juta Rupiah), batas maksimum tersebut dapat berubah sewaktu-waktu dan secara otomatis mengikuti perubahan dan/atau pemberlakuan ketentuan Bank Indonesia;
          akun Anda dapat menerima pengisian saldo RPT Cash (top up) dengan mengacu pada batasan yang dimaksud dalam poin (a) di atas; dan
          layanan pemrosesan transaksi pembayaran untuk pembelian barang dan/atau jasa atau untuk pembayaran tagihan.<br><br>
          RPT Premier (Registered E-Money)<br><br>
          RPT Premier adalah klasifikasi akun Pengguna RPT yang merupakan pengguna uang elektronik registered sebagaimana diatur dalam Peraturan Bank Indonesia yang mengatur mengenai penyelenggaraan uang elektronik.<br><br>
          Anda dapat melakukan upgrade klasifikasi akun Anda menjadi RPT Premier dengan menyerahkan data dan informasi sebagaimana Kami syaratkan dalam Sub-BAB mengenai Upgrade pada BAB ini.
          Pengguna RPT dengan klasifikasi RPT Premier dapat menggunakan seluruh layanan uang elektronik, yaitu:<br>
          akun Anda dapat menampung saldo RPT Cash dengan batas maksimum Rp20.000.000,- (dua puluh juta Rupiah), batas maksimum tersebut dapat berubah sewaktu-waktu dan secara otomatis mengikuti perubahan dan/atau pemberlakuan ketentuan Bank Indonesia;
          akun Anda dapat menerima pengisian saldo RPT Cash (top up) dengan mengacu pada batasan yang dimaksud dalam poin (a) di atas;
          layanan pemrosesan transaksi pembayaran untuk pembelian barang dan/atau jasa atau untuk pembayaran tagihan;
          layanan transfer dana saldo RPT Cash antar-Pengguna RPT;
          layanan transfer dana saldo RPT Cash ke rekening bank;
          layanan tarik tunai saldo RPT Cash melalui mitra resmi Kami atau kanal tarik tunai resmi Kami; dan
          layanan-layanan lainnya sebagaimana Kami kembangkan, sediakan, dan informasikan untuk dan kepada Anda di kemudian hari sesuai dengan ketentuan peraturan perundang-undangan yang berlaku.
          <br><br>
          Pendaftaran (RPT Club):<br>

          Untuk dapat melakukan pendaftaran Anda harus merupakan individu yang cakap secara hukum berdasarkan hukum Republik Indonesia untuk melakukan tindakan-tindakan hukum, yaitu; Anda harus berusia minimal 21 (dua puluh satu tahun), atau sudah menikah, dan tidak sedang berada di bawah pengampuan.<br><br> 
          Jika Anda melakukan pendaftaran padahal Anda bukan merupakan individu yang cakap secara hukum sebagaimana dimaksud dalam huruf a di atas ini maka Anda menyatakan dan menjamin bahwa pendaftaran Anda telah disetujui oleh orang tua, wali, atau pengampu Anda yang sah.<br><br>
          Pada saat melakukan pendaftaran Anda wajib memasukan nomor ponsel dan alamat email yang masih aktif, valid, dan akurat serta belum pernah didaftarkan sebelumnya kepada Kami, ke Aplikasi RPT atau Platform Partner yang merupakan kanal pendaftaran elektronik resmi Kami.<br><br>
          Anda wajib untuk memasukan kode one-time password ("OTP") yang Kami kirimkan kepada Anda melalui layanan pesan singkat (Short Messaging Service / SMS) ke nomor ponsel yang dimaksud dalam huruf c di atas dan Anda wajib untuk membuat security code berupa 6 (enam) digit angka yang tidak boleh dibuat secara berurutan atau berulang ("Security Code").
          Kode OTP dan Security Code merupakan kode rahasia yang digunakan untuk mengakses akun Anda dan memanfaatkan Layanan RPT. Oleh karena itu, Anda wajib untuk selalu menjaga keamanan dan kerahasiaan kode OTP dan Security Code. Demi keamanan akun Anda, mohon untuk tidak membagikan, menginformasikan, atau mengungkapkan kode OTP ataupun Security Code kepada pihak manapun termasuk petugas RPT, customer service, dll.<br><br> 
          Untuk kejelasan terkait pendaftaran (sign up), 1 (satu) Pengguna hanya dapat melakukan pendaftaran (sign up) untuk 1 (satu) akun dan 1 (satu) nomor ponsel hanya dapat digunakan untuk pendaftaran (sign up) satu kali.<br><br>
          Upgrade (RPT Premier):<br>

          Upgrade dilaksanakan untuk meningkatkan klasifikasi akun Anda dari RPT Club menjadi RPT Premier.
          Anda wajib melakukan upgrade hanya melalui kanal upgrade elektronik resmi Kami, yaitu; Aplikasi RPT, Platform Partner (yang Kami peruntukan sebagai kanal upgrade elektronik Kami), atau kanal upgrade lainnya sebagaimana Kami kembangkan, sediakan, dan informasikan untuk dan kepada Anda di kemudian hari.
          Anda wajib menyerahkan data dan informasi berupa; foto dokumen identitas; foto KTP bagi Anda yang merupakan WNI atau foto halaman identitas pada Paspor Anda bagi Anda yang merupakan WNA, secara valid, benar, akurat, dan jelas, melalui Aplikasi RPT atau Platform Partner yang Kami peruntukan sebagai kanal upgrade elektronik resmi Kami.
          Anda wajib menyerahkan swafoto Anda dan melakukan rekam verifikasi gerak wajah secara aktual, akurat, dan jelas, melalui Aplikasi RPT atau Platform Partner yang Kami peruntukan sebagai kanal upgrade elektronik resmi Kami.<br><br>
          Setiap satu dokumen identitas, swafoto, dan rekam verifikasi gerak wajah hanya dapat digunakan satu kali untuk pelaksanaan upgrade. 
          Kami berhak menolak permintaan upgrade Anda, jika dokumen identitas, swafoto, dan/atau rekam verifikasi gerak wajah yang Anda serahkan sudah pernah digunakan sebelum-nya atau tidak valid, benar, akurat, ataupun tidak dapat terlihat dengan jelas.<br><br>
          Untuk tujuan verifikasi atas data identitas Anda atau untuk tujuan substitusi data identitas, Kami dapat meminta Anda untuk menyerahkan data-data pendukung lainnya dari Anda, yang wajib Anda serahkan dalam jangka waktu sebagaimana Kami tentukan dan informasikan kepada Anda. Jika dalam jangka waktu tersebut Anda gagal dalam menyerahkan data-data pendukung tersebut maka Kami berhak untuk menolak permintaan upgrade Anda.<br><br>
          Dengan melakukan upgrade berarti Anda memberikan persetujuan Anda kepada Kami untuk mengumpulkan, menyimpan dan memproses data dan informasi Anda sebagaimana tercantum dalam dokumen identitas, swafoto, dan rekam verifikasi gerak wajah yang Anda serahkan kepada Kami untuk; tujuan upgrade dan pengelolaan serta pengawasan akun Pengguna RPT Anda.
          Kami memiliki wewenang penuh untuk menentukan hasil dari permintaan upgrade Anda berdasarkan hasil penilaian Kami terhadap data, informasi, dokumen, swafoto, dan/atau rekam verifikasi gerak wajah yang Anda serahkan<br><br>
          Nomor Ponsel dan Email:<br>

          Perlu diketahui bahwa akun Pengguna RPT Anda didasarkan pada nomor ponsel yang Anda daftarkan kepada Kami pada saat pendaftaran. Nomor ponsel Anda dibutuhkan untuk pengaksesan akun, pengiriman kode OTP, pelaksanaan transaksi pembayaran, pelaksanaan pengisian saldo RPT Cash (top up), komunikasi, dan pengiriman informasi-informasi penting lainnya.
          Kami juga meminta Anda untuk mendaftarkan alamat email Anda kepada Kami untuk tujuan penyediaan informasi-informasi penting lainnya terkait Aplikasi RPT.
          Anda wajib untuk memperbarui nomor ponsel dan/atau alamat email pada Aplikasi RPT Anda dengan segera, apabila Anda mengganti nomor ponsel dan/atau alamat email Anda agar Kami dapat memperbarui informasi kontak-kontak Anda dalam sistem Kami.<br><br>
          <strong class="fw-bold">B. PERLINDUNGAN DATA</strong><br><br>

          Kami akan berupaya sebaik mungkin untuk selalu menjaga keamanan dan kerahasiaan data dan informasi yang Anda serahkan kepada Kami maupun yang Kami kumpulkan dari Anda, terutama yang menyangkut data pribadi Anda dan aktivitas penggunaan Layanan RPT, yang diserahkan kepada Kami atau dikumpulkan oleh Kami untuk tujuan pendaftaran, upgrade, pengkinian data, penyampaian informasi, pengurusan klaim, analisa, pengembangan/peningkatan layanan, dan tujuan-tujuan lainnya sepanjang tidak bertentangan dengan peraturan perundang-undangan yang berlaku.
          Dengan memberikan persetujuan Anda terhadap Syarat dan Ketentuan ini berarti Anda juga telah menyetujui Pemberitahuan Privasi yang dapat Anda akses, baca, dan pelajari melalui tautan berikut: Pemberitahuan Privasi. Mohon untuk membaca dan mempelajari dengan seksama seluruh ketentuan-ketentuan dalam Pemberitahuan Privasi tersebut agar Anda mengerti dan memahami bagaimana Kami melindungi dan memproses data dan informasi Anda.
          Dalam hal Kami mengubah ketentuan dalam Pemberitahuan Privasi tersebut maka perubahan tersebut akan diberitahukan kepada Anda paling lambat 30 (tiga puluh) hari kerja sebelum perubahan tersebut berlaku efektif, dan perubahan tersebut akan dilaksanakan dengan mengacu pada ketentuan peraturan perundang-undangan yang berlaku.<br><br>
          <strong class="fw-bold">C. BIAYA LAYANAN</strong><br><br>

          Atas penyediaan Layanan RPT, Kami berhak untuk membebankan dan mengenakan biaya layanan kepada Anda, yaitu:
          biaya pengisian saldo (top-up) RPT Cash melalui mitra dan kanal top up, transfer dana, penyetoran langsung melalui Bank atau metode top up lainnya sebagaimana tersedia dari waktu ke waktu;
          biaya tarik tunai yang dilakukan melalui mitra resmi Kami atau kanal tarik tunai resmi Kami;
          biaya transfer dana RPT Cash antar-Pengguna RPT;
          biaya transfer dana RPT Cash dari Pengguna RPT ke rekening bank; dan
          biaya-biaya lainnya atas layanan-layanan yang Kami sediakan di kemudian hari yang akan Kami informasikan kepada Anda sebelum pembebanan atas biaya-biaya tersebut berlaku efektif sesuai dengan ketentuan peraturan perundang-undangan yang berlaku.
          Nilai biaya-biaya yang Kami bebankan dan kenakan kepada Anda adalah sebagaimana Kami informasikan kepada Anda melalui Aplikasi RPT, kanal top up resmi Kami, kanal tarik tunai resmi Kami, melalui Media Komunikasi, dan/atau Media Publikasi.
          Kecuali ditentukan dan Kami informasikan sebaliknya kepada Anda, seluruh pajak-pajak yang timbul dari penyediaan Layanan RPT akan ditanggung oleh masing-masing pihak sesuai dengan peraturan perundang-undangan yang berlaku khusus nya mengenai perpajakan sebagaimana diberlakukan, diperbarui, dan/atau diubah seterusnya oleh otoritas yang berwenang.<br><br>
          <strong class="fw-bold">D. PENGKINIAN DATA</strong><br><br>

          Kami menerapkan prinsip know your customer untuk tujuan keamanan dan pengawasan penggunaan Aplikasi RPT dan Layanan RPT, dan untuk tujuan kepatuhan terhadap peraturan perundang-undangan yang berlaku terkait dengan anti-pencucian uang dan pendanaan terorisme.
          Kami menerapkan prinsip know your customer, salah satunya, dengan melaksanakan proses uji tuntas konsumen (customer due diligence) "CDD" atas data dan informasi Anda yang Anda serahkan kepada Kami untuk tujuan pendaftaran dan upgrade akun. Oleh karena itu, Kami harus memastikan bahwa data dan informasi mengenai diri Anda yang Anda serahkan kepada Kami merupakan data dan informasi yang terkini dan valid.
          Pengkinian data dilakukan dengan cara menyerahkan dokumen identitas (KTP/Paspor), swafoto, dan/atau rekam verifikasi gerak wajah Anda yang terbaru secara valid, benar, lengkap dan jelas.
          Apabila Anda melakukan perubahan atas data dan informasi yang telah Anda serahkan kepada Kami maka Anda wajib untuk segera menghubungi Layanan Bantuan Pengguna untuk melakukan pengkinian data.<br><br>
          Anda juga wajib dengan segera melakukan pengkinian data, apabila Kami meminta Anda untuk melakukan pengkinian data berdasarkan salah satu atau lebih hal-hal di bawah ini:
          Temuan Kami atas perubahan atas data dan informasi Anda yang telah Anda serahkan sebelumnya kepada Kami.
          Temuan Kami atas indikasi perubahan pola transaksi (pembayaran atau transfer dana), ketidaksesuaian transaksi dengan profil Anda, atau peningkatan risiko yang signifikan atas hasil CDD Kami terhadap Anda selaku pengguna jasa Kami.<br><br>
          Temuan Kami atas indikasi tindakan pencucian uang, pendanaan terorisme, dan/atau pelanggaran peraturan perundang-undangan yang berlaku.
          Alasan lainnya sebagaimana Kami informasikan kepada Anda pada saat Kami meminta Anda melakukan pengkinian data.
          Apabila Kami meminta Anda untuk melakukan pengkinian data karena alasan-alasan yang dimaksud dalam Angka 5 di atas maka Kami akan menginformasikan dan memberikan batas waktu untuk Anda melakukan pengkinian data.
          Apabila Anda gagal untuk melaksanakan pengkinian data secara valid, benar, lengkap, dan jelas setelah berakhirnya batas waktu yang dimaksud dalam Angka 6 di atas maka Kami berhak untuk men-downgrade klasifikasi akun Anda. <br><br>
          <strong class="fw-bold">E. RPT CASH</strong><br><br>

          RPT Cash adalah uang elektronik berbasis server yang Kami terbitkan sesuai dengan ketentuan Peraturan Bank Indonesia yang mengatur mengenai penyelenggaraan uang elektronik. Untuk kejelasan, RPT Cash merupakan uang elektronik sehingga bukan merupakan simpanan sebagaimana dimaksud dalam peraturan perundang-undangan di bidang Perbankan yang dijamin oleh Lembaga Penjamin Simpanan.
          RPT Cash dapat digunakan untuk:<br>
          pelaksanaan transaksi pembayaran untuk pembelian dan/atau pembayaran barang dan/atau jasa atau pembayaran tagihan;
          pelaksanaan transfer dana antar-Pengguna RPT (untuk Pengguna RPT dengan klasifikasi akun RPT Premier); dan
          pelaksanaan transfer dana ke rekening bank (untuk Pengguna RPT dengan klasifikasi akun RPT Premier).
          Bagi Pengguna RPT dengan klasifikasi akun RPT Premier, saldo RPT Cash dapat ditarik secara tunai melalui mitra dan kanal tarik tunai resmi Kami.
          Pengguna RPT dapat melakukan pengisian saldo RPT Cash (top up) melalui mitra dan kanal top up resmi Kami, atau melalui transfer dana dari rekening Bank, penyetoran langsung melalui Bank, atau melalui kanal top up lainnya sebagaimana tersedia dari waktu ke waktu.
          Kami menerapkan batas maksimum nilai RPT Cash yang dapat masuk/diisikan/di-top up ke akun Pengguna RPT dalam kurun waktu 1 (satu) bulan, yaitu sebesar Rp40.000.000,- (empat puluh juta rupiah) bagi Pengguna RPT dengan klasifikasi akun RPT Premier atau Rp20.000.000 bagi Pengguna RPT dengan klasifikasi akun RPT Club. Batasan tersebut berlaku bagi transaksi yang bersifat<br><br> incoming atau transaksi masuk, yaitu:<br>
          setoran awal RPT Cash ke akun Pengguna RPT; 
          transfer dana masuk ke akun Pengguna RPT dari akun Pengguna RPT lainnya;
          transfer dana masuk ke akun Pengguna RPT dari rekening bank; dan
          pengisian ulang saldo RPT Cash (top up) ke akun Pengguna RPT. 
          Batasan yang dimaksud dalam ketentuan Angka 5 di atas dapat berubah sewaktu-waktu dan secara otomatis mengikuti perubahan dan/atau pemberlakuan ketentuan Bank Indonesia
          Untuk transaksi pembayaran yang difasilitasi dengan kode quick response berstandar Quick Response Code Indonesia Standard (QRIS), Kami menetapkan batas maksimum nominal transaksi pembayaran per transaksi sesuai dengan ketentuan yang diatur dan diberlakukan oleh Bank Indonesia.
          Pengisian saldo RPT Cash, baik dari setoran awal, pengisian ulang (top-up), atau transfer masuk (transfer-in), bukan merupakan pemberian pinjaman ataupun pendanaan dari Anda kepada Kami. Kami tidak memberikan janji dalam bentuk apapun atas bertambahnya nilai uang yang Anda titipkan kepada Kami dalam rangka pengisian saldo RPT Cash, baik dalam bentuk bunga atau apapun.<br><br>
          <strong class="fw-bold">F. RPT POINTS</strong><br><br>

          RPT Points adalah loyalty reward yang ditujukan bagi Pengguna RPT, yang memiliki nilai 1 poin sama dengan 1 Rupiah, yang ketentuan pemberian, perhitungan, dan penggunaannya berbeda dengan RPT Cash. Nilai RPT Points dijamin dengan suatu dana yang memadai sesuai dengan nilai RPT Points yang Anda terima.
          Pemberian, perhitungan, dan penukaran/penggunaan RPT Points ditentukan oleh:
          kebijakan internal Kami;
          kesepakatan Kami dengan mitra-mitra Kami yang bekerja sama dengan Kami dalam pemberian, perhitungan, dan/atau penukaran/penggunaan RPT Points; dan/atau
          ketentuan peraturan perundang-undangan yang berlaku atau kebijakan dari otoritas atau instansi yang berwenang.
          Sehubungan dengan ketentuan dalam Angka 2 di atas, Anda dengan ini mengerti dan memahami bahwa pemberlakuan atas pemberian, perhitungan, dan penukaran/penggunaan RPT Points dapat berbeda-beda tergantung dari hal-hal yang disebutkan dalam Angka 2 di atas.<br><br>
          Kepatuhan:<br>
          Kebijakan internal Kami dan kesepakatan Kami dengan pihak-pihak yang bekerja sama dengan Kami terkait dengan pemberian, perhitungan, dan penukaran/penggunaan RPT Points akan dibuat dan dilaksanakan dengan tetap tunduk pada ketentuan peraturan perundang-undangan yang berlaku atau kebijakan dari otoritas atau instansi yang berwenang.<br><br>
          Pemberian RPT Points:<br>
          RPT Points akan diberikan kepada Anda sebagai bentuk loyalty reward setiap kali Anda berhasil melakukan transaksi, dengan menggunakan RPT Cash, pada Merchant yang diikutsertakan dalam pelaksanaan suatu program promosi (cashback) yang sedang diberlakukan, atau setiap kali Anda memenuhi syarat untuk mendapatkan RPT Points berdasarkan program promosi yang Kami dan/atau mitra Kami terapkan.
          Jumlah RPT Points yang diberikan kepada Anda dapat berbeda-beda bergantung pada ketentuan program promosi yang diberlakukan.
          RPT Points akan diberikan sepanjang Anda memenuhi syarat untuk mendapatkan RPT Points dan Anda belum melewati batasan maksimum pemberian RPT Points sesuai dengan ketentuan program promosi yang berlaku.<br><br>
          Penukaran/Penggunaan RPT Points:<br>
          Anda dapat menukarkan (redeem) RPT Points pada Merchant-Merchant yang Kami tentukan untuk dapat menerima penukaran (redeem) RPT Points, untuk pembayaran atas barang dan/atau jasa pada Merchant-Merchant tersebut. 
          Perlu Anda ketahui bahwa tidak semua Merchant dapat menerima penukaran RPT Points, RPT Points hanya dapat ditukarkan pada Merchant yang telah Kami tetapkan untuk dapat melakukan penerimaan penukaran (redeem) RPT Points.
          Perlu Anda ketahui bahwa Kami juga dapat menetapkan pembatasan dalam penukaran/penggunaan RPT Points pada Merchant. Oleh karena itu, mohon diingat juga bahwa tidak semua Merchant dapat menerima penukaran RPT Points secara penuh atau menerima kombinasi penukaran RPT Points dengan pembayaran menggunakan RPT Cash. 
          Dalam hal Anda melakukan penukaran RPT Points pada Merchant yang dapat menerima penukaran RPT Points secara kombinasi dengan pembayaran RPT Cash maka Kami dapat menentukan persentase RPT Points yang dapat ditukarkan secara kombinasi dengan pembayaran RPT Cash tersebut.<br><br>
          Batasan dan Larangan:<br>
          Setiap RPT Points yang Anda dapatkan tidak dapat diklaim atau ditukarkan menjadi uang, baik melalui Kami atau pihak manapun, atau dialihkan atau dipindahtangankan kepada pihak manapun, baik dengan cara ditransfer atau dicairkan/diuangkan, atau dengan cara-cara apapun.<br><br>
          Kami melarang pelaksanaan pelaksanaan salah satu atau lebih dari tindakan-tindakan berikut:<br>
          Tindakan yang dimaksudkan untuk mengalihkan atau memindahtangankan kepemilikan RPT Points kepada pihak lainnya.
          Tindakan yang dimaksudkan untuk mencairkan atau menguangkan RPT Points.
          Tindakan yang dimaksudkan untuk mendapatkan keuntungan pribadi dengan cara-cara yang melanggar peraturan perundang-undangan yang berlaku atau melanggar hak atau kepentingan Kami atau pihak lain manapun.<br><br>
          Tindakan yang dimaksudkan untuk mendapatkan atau memperoleh RPT Points dengan cara-cara curang, contoh: transaksi fiktif/palsu untuk mendapatkan RPT Points atau Tindakan Penyalahgunaan atau tindakan manipulatif untuk mengelabui dan memperdaya sistem Kami atau suatu program promosi untuk mendapatkan RPT Points.<br><br>
          Masa Berlaku RPT Points:<br>
          RPT Points yang Anda terima akan memiliki masa berlaku 16 (enam belas) bulan sejak RPT Points tersebut diberikan kepada Anda.
          Setelah masa berlaku tersebut terlampaui maka RPT Points akan hangus dan tidak berlaku lagi, dan akan hilang dari catatan saldo RPT Points Anda.
          RPT Points Anda akan hangus, jika terjadi Penutupan secara permanen atas akun Pengguna RPT Anda.
          Kami berhak untuk menarik kembali, memotong, dan/atau membatalkan pemberian dan/atau membatasi penggunaan RPT Points Anda, karena salah satu atau lebih kondisi berikut:
          Transaksi yang mendasari diberikannya RPT Points tersebut dibatalkan.
          Transaksi yang mendasari diberikannya RPT Points tersebut terindikasi merupakan suatu tindakan kecurangan, tindakan pelanggaran atas Syarat dan Ketentuan ini dan/atau peraturan perundang-undangan yang berlaku.
          Terdapat kesalahan sistem/perhitungan dari sisi Kami yang menyebabkan Anda menerima RPT Points yang seharusnya tidak Anda terima.
          Kami diharuskan untuk menarik kembali, memotong, membatalkan pemberian, dan/atau membatasi penggunaan RPT Points Anda oleh otoritas atau instansi yang berwenang.
          Terjadinya salah satu atau lebih kondisi-kondisi yang menyebabkan Kami harus melakukan koreksi sebagaimana diatur dalam BAB mengenai Koreksi.
          Dalam hal kami harus melaksanakan penarikan kembali, pemotongan, dan/atau pembatalan RPT Points Anda maka Kami akan menginformasikannya kepada Anda melalui Aplikasi RPT, Media Komunikasi, atau Media Publikasi.
          Apabila Anda memiliki pertanyaan terkait RPT Points, mohon mengacu pada pusat bantuan yang terdapat pada Aplikasi RPT atau Anda dapat menghubungi Layanan Bantuan Pengguna sebagaimana diatur dalam BAB tentang Layanan Bantuan Pengguna dari Syarat dan Ketentuan ini.<br><br>
          <strong class="fw-bold">G. DORMANT ACCOUNT</strong><br><br>

          Sistem Kami akan menganggap akun Pengguna RPT Anda sebagai akun terbengkalai atau "Dormant Account", apabila akun Anda tidak melaksanakan aktivitas transaksi pembayaran ataupun transfer dana keluar (transfer-out) selama 6 (enam) bulan berturut-turut.
          Sistem Kami akan berhenti menganggap akun Anda sebagai Dormant Account, apabila Anda melakukan transaksi pembayaran atau transfer dana (transfer-out) menggunakan akun Anda tersebut yang telah dianggap sebagai Dormant Account.
          Kami, berdasarkan kebijakan Kami, berhak untuk melakukan Pemblokiran, Penutupan secara permanen, dan/atau Downgrade atas akun Anda, jika akun Anda menjadi Dormant Account.
          Tindakan yang Kami lakukan atas Dormant Account (Pemblokiran, Penutupan, dan/atau Downgrade) akan diinformasikan kepada pemilik atas Dormant Account tersebut melalui Aplikasi RPT, Media Komunikasi, atau Media Publikasi.<br><br>
          <strong class="fw-bold">H. PEMBLOKIRAN, PENUTUPAN, DAN DOWNGRADE AKUN</strong><br><br>
          Pemblokiran Akun:<br>
          "Pemblokiran" adalah penonaktifan akun Pengguna RPT Anda untuk jangka waktu tertentu. Pemblokiran akun dapat terjadi karena salah satu atau lebih hal-hal berikut ini:
          Akun Anda menjadi Dormant Account, dan Kami, berdasarkan kebijakan Kami, memutuskan untuk melakukan Pemblokiran atas akun Anda.
          Anda meminta Kami untuk melakukan Pemblokiran atas akun Anda untuk tujuan keamanan atau tujuan lainnya. Dalam hal Anda meminta sendiri Pemblokiran akun Anda, Anda akan melalui prosedur verifikasi untuk membuktikan bahwa permintaan Pemblokiran memang terbukti dari Anda selaku pemilik akun yang sah. Atas permintaan Pemblokiran, Kami akan melakukan tinjauan dan verifikasi atas permintaan tersebut, dan atas kebijakan dan pertimbangan Kami, Kami akan memutuskan untuk melakukan Pemblokiran atas akun Anda atau tidak.
          Kami harus melakukan Pemblokiran untuk keamanan akun Anda karena terdapat indikasi terjadinya insiden keamanan terhadap akun Anda.
          Kami mendapatkan perintah dari otoritas, instansi, atau pengadilan yang berwenang, atau peraturan perundang-undangan yang berlaku untuk melakukan Pemblokiran atas akun Anda.
          Kami menemukan indikasi bahwa akun Anda digunakan untuk melakukan suatu tindakan yang merupakan pencucian uang, pendanaan terorisme, atau tindakan pelanggaran atas peraturan perundang-undangan yang berlaku, ATAU indikasi bahwa Anda melakukan suatu tindakan yang merupakan pencucian uang, pendanaan terorisme, atau tindakan pelanggaran atas peraturan perundang-undangan yang berlaku.<br><br>
          Kami menemukan indikasi bahwa akun Anda digunakan untuk melakukan suatu tindakan yang merupakan pelanggaran atas ketentuan-ketentuan yang diatur dalam Syarat dan Ketentuan ini atau Tindakan Penyalahgunaan, ATAU indikasi bahwa Anda melanggar salah satu atau lebih ketentuan-ketentuan yang diatur dalam Syarat dan Ketentuan ini atau melakukan Tindakan Penyalahgunaan.
          Akun Anda terbukti telah digunakan untuk melakukan suatu tindakan yang merupakan pelanggaran atas satu atau lebih ketentuan-ketentuan yang diatur dalam Syarat dan Ketentuan ini atau Tindakan Penyalahgunaan, ATAU Anda terbukti telah melanggar salah satu atau lebih ketentuan-ketentuan yang diatur dalam Syarat dan Ketentuan ini atau melakukan Tindakan Penyalahgunaan.
          Kami menemukan indikasi bahwa akun Anda digunakan untuk suatu pelanggaran atas peraturan perundang-undangan yang berlaku atau pelanggaran atas kepentingan pihak lain manapun, ATAU indikasi bahwa Anda melakukan suatu pelanggaran atas peraturan perundang-undangan yang berlaku atau pelanggaran atas kepentingan pihak lain manapun.
          Kami mendapatkan laporan yang disertai dengan bukti permulaan yang cukup dari pihak lain bahwa akun Anda digunakan untuk melakukan tindakan-tindakan yang dapat merugikan Kami dan/atau pihak lain manapun, ATAU bahwa Anda melakukan tindakan-tindakan yang dapat merugikan Kami dan/atau pihak lain manapun.<br><br>
          Pemblokiran akun akan menyebabkan akun Anda tidak dapat digunakan untuk mengakses dan menggunakan Aplikasi RPT dan Layanan RPT sampai dengan akun Anda diaktifkan kembali.
          Kami akan menginformasikan Anda mengenai Pemblokiran akun Anda melalui Aplikasi RPT, Media Komunikasi, atau Media Publikasi. Anda juga dapat menanyakan secara lebih lanjut hal-hal terkait dengan Pemblokiran akun Anda ke Layanan Bantuan Pengguna.<br><br>
          Pengakhiran Pemblokiran:<br>
          Apabila Kami melakukan Pemblokiran atas akun Anda karena alasan yang disebutkan dalam Angka 1 huruf a poin (1), (2) atau (3) di atas, Anda dapat meminta pengakhiran Pemblokiran akun Anda dengan menghubungi Layanan Bantuan Pengguna. Atas permintaan pengakhiran Pemblokiran, Kami akan melakukan tinjauan dan verifikasi, dan atas kebijakan dan pertimbangan Kami, Kami akan memutuskan untuk mengakhiri Pemblokiran atau melanjutkan Pemblokiran tersebut.<br><br>
          Apabila Kami melakukan Pemblokiran atas akun Anda karena alasan yang disebutkan dalam Angka 1 huruf a poin (4) di atas, Kami dapat mengakhiri Pemblokiran akun Anda, jika Kami mendapatkan perintah atau persetujuan dari otoritas, instansi, atau pengadilan yang berwenang dimaksud untuk mengakhiri Pemblokiran tersebut.
          Apabila Kami melakukan Pemblokiran atas akun Anda karena salah satu atau lebih alasan yang disebutkan dalam Angka 1 huruf a poin (5) - (9) di atas, Anda dapat mengajukan sangkalan kepada Kami atas tindakan Pemblokiran yang Kami lakukan atas akun Anda tersebut dengan menyerahkan bukti-bukti pendukung untuk menyangkal dasar-dasar yang Kami terapkan untuk melakukan Pemblokiran tersebut. Berdasarkan sangkalan dan bukti yang Anda ajukan, Kami akan melakukan investigasi lebih lanjut untuk menentukan apakah Kami harus mengakhiri atau melanjutkan Pemblokiran atas akun Anda atau melakukan penonaktifan atas akun Anda secara permanen (Penutupan).<br><br>
          Prosedur verifikasi untuk membuktikan bahwa Anda merupakan pemilik akun yang sah berlaku pada saat Anda meminta pengakhiran Pemblokiran akun Anda.<br><br>
          Penutupan Akun:<br>
          "Penutupan" adalah penonaktifan akun Pengguna RPT Anda secara permanen. Penutupan atas akun Anda dapat terjadi karena salah satu atau lebih hal-hal berikut ini:<br>
          Akun Anda menjadi Dormant Account, dan Kami, berdasarkan kebijakan Kami, memutuskan untuk melakukan Penutupan atas akun Anda.
          Kami mendapatkan perintah dari otoritas, instansi, atau pengadilan yang berwenang atau berdasarkan peraturan perundang-undang yang berlaku untuk melakukan Penutupan atas akun Anda.
          Anda meminta Kami untuk melakukan Penutupan atas akun Anda untuk tujuan keamanan atau tujuan lainnya. Dalam hal Anda meminta sendiri Penutupan akun Anda, Anda akan melalui prosedur verifikasi untuk membuktikan bahwa permintaan Penutupan memang terbukti dari Anda selaku pemilik akun yang sah. Atas permintaan Penutupan, Kami akan melakukan tinjauan dan verifikasi atas permintaan tersebut, dan atas kebijakan dan pertimbangan Kami, Kami akan memutuskan untuk melakukan Penutupan atas akun Anda atau tidak.
          Akun Anda terbukti telah digunakan untuk melakukan suatu tindakan yang merupakan pelanggaran atas salah satu atau lebih ketentuan-ketentuan yang diatur dalam Syarat dan Ketentuan ini atau melakukan Tindakan Penyalahgunaan, ATAU Anda terbukti telah melanggar salah satu atau lebih ketentuan-ketentuan yang diatur dalam Syarat dan Ketentuan ini atau melakukan Tindakan Penyalahgunaan.<br><br>
          Akun Anda terbukti telah digunakan untuk pencucian uang, pendanaan kegiatan terorisme, atau tindakan pelanggaran atas peraturan perundang-undangan yang berlaku, ATAU Anda terbukti telah melakukan tindakan yang merupakan pencucian uang, pendanaan kegiatan terorisme, atau tindakan pelanggaran atas peraturan perundang-undangan yang berlaku.
          Akun Anda terbukti telah digunakan untuk melakukan suatu tindakan yang merupakan pelanggaran atas peraturan perundang-undangan yang berlaku atau pelanggaran atas kepentingan pihak lain manapun, ATAU Anda terbukti telah melakukan tindakan yang merupakan pelanggaran atas peraturan perundang-undangan yang berlaku atau pelanggaran atas kepentingan pihak lain manapun.
          Akun Anda terbukti telah digunakan untuk melakukan suatu tindakan yang dapat merugikan Kami dan/atau pihak manapun, ATAU Anda terbukti telah melakukan tindakan-tindakan yang dapat merugikan Kami dan/atau pihak lain manapun.<br><br>
          Kami, berdasarkan pertimbangan dan kebijakan Kami sendiri, memutuskan bahwa Penutupan atas akun Anda harus dilakukan untuk tujuan keamanan Kami dan/atau pihak lain manapun.
          Anda, berdasarkan bukti dan keterangan yang cukup, dinyatakan telah meninggal dunia.
          Anda mencabut persetujuan yang Anda telah berikan kepada Kami untuk menyimpan dan memproses seluruh data dan informasi Anda yang Kami butuhkan untuk pengelolaan dan pengawasan akun Pengguna RPT Anda, atau Anda meminta Kami untuk berhenti memproses, menghapus dan/atau menghancurkan data dan informasi Anda yang Kami butuhkan untuk pengelolaan dan pengawasan akun Pengguna RPT Anda. Dalam hal Anda mencabut persetujuan sebagaimana dimaksud dalam ketentuan ini, Anda akan melalui prosedur verifikasi untuk membuktikan bahwa pencabutan persetujuan tersebut memang terbukti dari Anda selaku pemilik akun yang sah.
          Penutupan akun akan menyebabkan akun Anda tidak dapat digunakan untuk mengakses dan menggunakan Aplikasi RPT dan Layanan RPT secara permanen (dinonaktifkan secara permanen) dan secara keseluruhan.<br><br>
          Kami akan menginformasikan Anda mengenai Penutupan akun Anda melalui Aplikasi RPT, Media Komunikasi, atau Media Publikasi. Anda juga dapat menanyakan secara lebih lanjut hal-hal terkait dengan Penutupan akun Anda ke Layanan Bantuan Pengguna.<br><br>
          Pelaksanaan Penutupan Akun:<br>
          Penahanan, dan/atau Pendebetan/Pemotongan RPT Cash:<br>
          Jika pada saat Penutupan akun, Anda masih memiliki kewajiban-kewajiban yang masih belum dibayarkan kepada mitra-mitra Kami dan/atau Kami, yang mana pelunasannya harus dilakukan menggunakan RPT Cash, maka Anda sepakat bahwa Kami berhak untuk menahan dan memotong/mendebet saldo RPT Cash Anda untuk tujuan pelunasan kewajiban-kewajiban dimaksud.
          Jika Penutupan atas akun Anda terjadi karena alasan yang dimaksud dalam Angka 2 huruf a poin (2) di atas, dan otoritas, instansi, atau pengadilan yang berwenang, atau peraturan perundang-undangan yang berlaku memberikan perintah atau arahan kepada Kami untuk melakukan penahanan, dan/atau pemotongan/pendebetan atas saldo RPT Cash dan/atau RPT Points Anda maka Kami akan melakukan penahanan, dan/atau pemotongan/pendebetan atas saldo RPT Cash dan/atau RPT Points Anda sesuai dengan perintah atau arahan tersebut.
          Jika Anda meminta sendiri Penutupan akun Anda namun Anda masih memiliki kewajiban yang belum terbayarkan sebagaimana dimaksud dalam poin (a) di atas maka Kami berhak untuk menolak permintaan Penutupan akun Anda.<br><br>
          Klaim RPT Cash:<br>
          Anda dapat mengajukan klaim atas saldo RPT Cash Anda yang masih tersisa dalam akun Anda dengan menghubungi Layanan Bantuan Pengguna sebagaimana diatur dalam BAB tentang Layanan Bantuan Pengguna dari Syarat dan Ketentuan ini.<br><br>
          Dalam hal pelaksanaan klaim saldo RPT Cash mengharuskan terjadinya pelaksanaan transfer dana dari Kami ke rekening bank yang Anda tentukan maka Kami akan mengenakan biaya transfer dana ke rekening yang Anda tujukan untuk menerima pencairan saldo RPT Cash tersebut sesuai dengan ketentuan peraturan perundang-undangan yang berlaku.
          Untuk kejelasan, apabila pada saat Penutupan akun Anda terjadi, Anda masih memiliki saldo RPT Points maka saldo RPT Points Anda dalam akun tersebut akan hangus dengan seketika.<br><br>
          Penutupan Akun Pengguna RPT yang Meninggal Dunia:<br>
          Jika Pengguna RPT meninggal dunia maka ahli waris yang sah dari Pengguna RPT tersebut, yang wajib dibuktikan dengan menyerahkan kepada Kami surat kematian Pengguna RPT, bukti yang sah sebagai ahli waris, dan surat persetujuan ahli waris lainnya, dapat mengajukan Penutupan atas akun Pengguna RPT tersebut, dan mengurus klaim atas saldo RPT Cash yang masih tersisa pada akun Pengguna RPT tersebut. Terkait dengan hal ini, Anda dapat menghubungi Layanan Bantuan Pengguna untuk informasi lebih lanjut.
          Downgrade Akun:<br>
          "Downgrade" adalah penurunan klasifikasi akun Pengguna RPT dari RPT Premier menjadi RPT Club. Downgrade atas akun Anda dapat terjadi karena salah satu atau lebih hal-hal berikut ini:
          Akun Anda terbukti telah digunakan untuk melakukan suatu tindakan yang merupakan pelanggaran atas salah satu atau lebih ketentuan-ketentuan dari Syarat dan Ketentuan ini, ATAU Anda terbukti telah melakukan pelanggaran atas salah satu atau lebih ketentuan-ketentuan dari Syarat dan Ketentuan ini.
          Kami mendapatkan perintah dari otoritas atau instansi yang berwenang untuk melakukan Downgrade atas akun Anda.
          Anda meminta Kami untuk tidak lagi menyimpan, dan/atau menghapus dan menghancurkan data dan informasi Anda yang Kami butuhkan untuk penerapan prinsip know your customer, sehingga Kami tidak lagi dapat mengklasifikasikan akun Anda sebagai pengguna terdaftar (registered) atau akun RPT Premier. Dalam hal Anda meminta sendiri untuk tidak lagi menyimpan, dan/atau menghapus dan menghancurkan data dan informasi Anda, Anda akan melalui prosedur verifikasi untuk membuktikan bahwa permintaan dimaksud memang terbukti dari Anda selaku pemilik akun yang sah.
          Anda meminta Kami untuk melakukan Downgrade atas akun Anda untuk tujuan keamanan atau tujuan lainnya yang berdasarkan pertimbangan Kami dapat diterima sebagai dasar atas Downgrade akun Anda. Dalam hal Anda meminta sendiri Downgrade, Anda akan melalui prosedur verifikasi untuk membuktikan bahwa permintaan Downgrade memang terbukti dari Anda selaku pemilik akun yang sah. Atas permintaan Downgrade, Kami akan melakukan tinjauan dan verifikasi atas permintaan tersebut, dan atas kebijakan dan pertimbangan Kami, Kami akan memutuskan untuk melakukan Downgrade atas akun Anda atau tidak.<br><br>
          Kami berdasarkan pertimbangan dan kebijakan Kami sendiri memutuskan bahwa Downgrade atas akun Anda harus dilakukan untuk tujuan keamanan Kami, Anda dan/atau pihak lain manapun.
          Anda gagal untuk melaksanakan pengkinian data secara valid, benar, lengkap, dan jelas setelah berakhirnya jangka waktu yang telah Kami berlakukan dan informasikan kepada Anda (baca BAB tentang Pengkinian Data).<br><br>
          Akun Anda menjadi Dormant Account dan Kami, berdasarkan kebijakan Kami, memutuskan untuk melakukan Downgrade atas akun Anda.
          Apabila Kami melakukan Downgrade atas akun Anda karena alasan-alasan yang dimaksud dalam Angka 3 huruf a poin (3) - (7), Anda dapat mengajukan upgrade kembali atas klasifikasi akun Anda kepada Kami dengan mengikuti prosedur pelaksanaan upgrade akun yang diatur dalam Syarat dan Ketentuan ini. Keputusan atas hasil pengajuan upgrade kembali merupakan sepenuh wewenang Kami.
          Kami akan menginformasikan Anda mengenai Downgrade atas klasifikasi akun Anda melalui Aplikasi RPT atau Media Komunikasi, atau Media Publikasi.<br><br>
          <strong class="fw-bold">I. KOREKSI</strong><br><br>

          Kami berhak untuk melakukan koreksi atas saldo RPT Cash dan RPT Points pada akun Anda (memotong/mendebet atau mengkredit), dan melakukan koreksi atas informasi-informasi terkait dengan transaksi pembayaran, transfer dana, ataupun penyediaan layanan-layanan Kami lainnya pada Aplikasi RPT. <br><br>
          Kami berhak untuk melakukan Koreksi sebagaimana dimaksud dalam Angka 1 di atas, karena salah satu atau lebih kondisi-kondisi berikut ini:
          Terjadi kesalahan atau kekeliruan yang menyebabkan kesalahan pada RPT Cash dan/atau RPT Points Anda, atau informasi Anda, atau yang menyebabkan Anda menerima RPT Cash, RPT Points, dan/atau layanan yang tidak seharusnya Anda terima.<br><br>
          Terdapat permintaan langsung atau persetujuan dari pemilik akun yang akan menerima pelaksanaan koreksi.
          Terdapat permintaan langsung dari kepolisian, pengadilan, atau otoritas berwenang.
          Akun Anda terbukti telah digunakan untuk melakukan suatu tindakan yang merupakan pelanggaran atas Syarat dan Ketentuan ini atau Tindakan Penyalahgunaan, ATAU Anda terbukti melakukan pelanggaran atas Syarat dan Ketentuan ini atau melakukan Tindakan Penyalahgunaan. Sehingga Kami berhak untuk melakukan koreksi (memotong atau mendebet) saldo RPT Cash dan/atau RPT Points Anda untuk ganti rugi atas kerugian material yang timbul dari pelanggaran atau Tindakan Penyalahgunaan tersebut.
          Terjadi kondisi-kondisi lainnya yang menyebabkan Anda menerima layanan, saldo RPT Cash, RPT Points, dan/atau informasi yang seharusnya tidak Anda terima.
          Terhadap permintaan atas koreksi dari pemilik akun, Kami akan melakukan koreksi berdasarkan pertimbangan dan kebijakan Kami setelah Kami melakukan tinjauan, verifikasi, atau investigasi terhadap validitas permintaan tersebut. Terkait dengan hal ini, Kami hanya akan melaksanakan koreksi, jika permintaan tersebut merupakan permintaan yang valid berdasarkan pertimbangan dan kebijakan Kami serta hasil dari tinjauan, verifikasi, atau investigasi yang Kami lakukan. Kami berhak untuk menentukan pelaksanaan atas koreksi yang berasal dari permintaan pemilik akun berdasarkan pertimbangan dan kebijakan Kami serta hasil dari tinjauan, verifikasi, atau investigasi yang Kami lakukan.
          Terhadap permintaan atas koreksi dari kepolisian, pengadilan, atau otoritas berwenang, Kami akan melakukan koreksi berdasarkan perintah yang Kami terima secara langsung dari kepolisian, pengadilan, atau otoritas berwenang dimaksud.<br><br>
          Kami akan menginformasikan Anda mengenai koreksi yang Kami lakukan melalui Aplikasi RPT, Media Komunikasi, atau Media Publikasi.
          Sehubungan dengan pelaksanaan koreksi, Anda sepakat untuk memberikan kuasa kepada kami untuk melakukan koreksi atas saldo RPT Cash, RPT Points, dan informasi relevan lainnya pada akun Anda, dan Anda juga juga sepakat untuk membebaskan Kami dari setiap tuntutan, gugatan, klaim, dan/atau ganti rugi berkaitan dengan pelaksanaan koreksi tersebut sepanjang Kami dapat membuktikan bahwa pelaksanaan koreksi didasarkan pada informasi dan bukti yang valid.
          Anda dapat menghubungi Layanan Bantuan Pengguna untuk mendapatkan informasi lebih lanjut mengenai koreksi.<br><br>
          <strong class="fw-bold">J. PRODUK KEUANGAN</strong><br><br>

          Penawaran atas Produk Keuangan berupa produk asuransi ("Produk Keuangan (Asuransi)") dapat Anda akses pada suatu halaman khusus di Aplikasi RPT yaitu pada widget RPT | Proteksi yang dikelola oleh PT Visionet Internasional Inovasi ("PT VII") sebagai mitra Kami. Penawaran atas Produk Keuangan (Asuransi) sepenuhnya dikelola oleh PT VII melalui kerja sama dengan broker-broker asuransi yang telah memiliki izin dari Otoritas Jasa Keuangan (OJK).
          Penawaran atas Produk Keuangan berupa produk pinjaman (“Produk Keuangan (Pinjaman)”) dapat Anda akses pada suatu halaman khusus di Aplikasi RPT yaitu halaman RPT | Pinjaman yang dikelola oleh Kami melalui kerja sama dengan PT Info Tekno Siaga (“Adapundi”) sebagai mitra kami yang memiliki izin yang merupakan perusahaan yang bergerak di bidang penyediaan Layanan Pendanaan Bersama Berbasis Teknologi Informasi (LPBBTI) serta diawasi oleh Otoritas Jasa Keuangan.
          Apabila Anda tertarik atas penawaran Produk Keuangan (Asuransi) maupun Produk Keuangan (Pinjaman) dan bermaksud untuk mengajukan pendaftaran atau membeli salah satu atau lebih dari Produk Keuangan yang dimaksud, maka Anda akan diminta untuk memberikan persetujuan Anda atas syarat dan ketentuan khusus mengenai:
          penggunaan fitur atau halaman penawaran Produk Keuangan yang Anda akses di Aplikasi RPT;
          penyediaan Produk Keuangan yang diberlakukan oleh penyedia Produk Keuangan; dan
          pengajuan pendaftaran untuk menjadi anggota atau pengguna dari Produk Keuangan tersebut yang diberlakukan oleh penyedia Produk Keuangan.
          Apabila Anda hendak menggunakan Produk Keuangan (Asuransi), Anda perlu mengakses halaman pada widget RPT | Proteksi dan mengajukan pendaftaran sebagai nasabah asuransi dengan mengirimkan dan menyerahkan data dan informasi Anda untuk keperluan penerapan dan pelaksanaan prinsip know your customer oleh broker asuransi yang bekerjasama dengan PT VII.
          Apabila Anda hendak menggunakan Produk Keuangan (Pinjaman), Anda mengakui dan menyetujui bahwa RPT dalam hal ini hanya bertindak sebagai penyedia platform, sehingga setiap Pengguna RPT yang ingin mendaftar dan mendapatkan layanan pinjaman dari Adapundi melalui Aplikasi RPT akan langsung diarahkan ke halaman atau antarmuka yang dikelola dan disediakan oleh Adapundi. Seluruh data (termasuk namun tidak terbatas pada data pribadi) yang Anda berikan melalui halaman khusus ini akan sepenuhnya diterima, diproses dan dikelola oleh Adapundi.
          Sehubungan dengan ketentuan pada Angka 5 di atas, seluruh kegiatan terkait dengan pinjam meminjam ataupun pelaksanaan atas kesepakatan pinjam meminjam yang dilakukan antara Adapundi dan Anda akan sepenuhnya menjadi tanggung jawab Adapundi dan Anda, termasuk namun tidak terbatas pada risiko kredit, kewajiban, gagal bayar dan akibat hukum lainnya yang lahir dari kegiatan pinjam meminjam yang diajukan melalui halaman khusus ini. Setiap pengajuan pinjaman yang diajukan melalui halaman khusus ini akan diputuskan sepenuhnya oleh Adapundi. RPT tidak bertanggung jawab atas segala kegiatan pinjam meminjam yang Anda ajukan melalui halaman RPT | Pinjaman.
          Apabila Produk Keuangan yang Anda beli merupakan Produk Berlangganan maka pelaksanaan atas transaksi pembelian tersebut tunduk pada BAB tentang Transaksi Berulang (Recurring Transactions) dari Syarat dan Ketentuan ini.<br><br>
          <strong class="fw-bold">K. KEWAJIBAN, LARANGAN, DAN PELANGGARAN</strong><br><br>

          Kewajiban:<br>
          Anda wajib untuk mengakses dan menggunakan akun Anda, Aplikasi RPT, Layanan RPT, dan Produk Lainnya, dengan itikad baik, tujuan yang sah dan tidak melanggar peraturan perundang-undangan yang berlaku, dan sesuai dengan Syarat dan Ketentuan ini.
          Anda wajib untuk mengakses dan menggunakan akun Anda, Aplikasi RPT, Layanan RPT, dan Produk Lainnya dengan penuh kehati-hatian, secara cermat, dan teliti.
          Anda wajib mematuhi dan melaksanakan seluruh ketentuan-ketentuan yang diatur dalam Syarat dan Ketentuan ini.<br><br>
          Larangan:<br>
          Anda dilarang untuk melakukan atau terlibat dalam pelaksanaan tindakan-tindakan yang merupakan Tindakan Penyalahgunaan.
          Anda dilarang untuk melakukan pengisian ulang saldo RPT Cash (top up) menggunakan sumber dana yang ilegal atau sumber dana yang didasarkan pada fasilitas kredit atau sumber dana yang bukan merupakan sumber dana uang (tunai).
          Anda dilarang untuk menyediakan jasa penguangan RPT Cash dan/atau RPT Points kepada masyarakat.
          Anda dilarang untuk menyediakan jasa pengisian saldo RPT Cash (top up) kepada masyarakat, tanpa persetujuan tertulis terlebih dahulu dari Kami.
          Anda dilarang untuk menyediakan jasa transfer dana melalui RPT Cash kepada masyarakat, tanpa persetujuan tertulis terlebih dahulu dari Kami.
          Anda dilarang untuk mengatasnamakan Kami (PT Visionet Internasional) dan/atau afiliasi-afiliasi Kami dalam melaksanakan tindakan-tindakan apapun, tanpa persetujuan tertulis terlebih dahulu dari Kami.
          Anda dilarang untuk memberikan pernyataan dan jaminan yang tidak benar atau menyesatkan terkait dengan Syarat dan Ketentuan ini.
          Anda dilarang untuk menyediakan data dan informasi palsu atau menyesatkan yang dapat merugikan Kami, Pengguna RPT lainnya, dan/atau pihak manapun. 
          Anda dilarang untuk melakukan transaksi yang dimaksudkan untuk menguangkan atau mengalihkan kepemilikan RPT Points.
          Anda dilarang untuk melakukan tindakan-tindakan yang merupakan pelanggaran atas Syarat dan Ketentuan ini, peraturan perundang-undangan yang berlaku, dan kepentingan pihak manapun.<br><br>
          Pelanggaran:<br>
          Pelanggaran atas salah satu atau lebih ketentuan-ketentuan dalam Syarat dan Ketentuan ini, baik yang Anda lakukan atau yang terjadi dari akun Anda, menyebabkan Kami berhak untuk mengambil salah satu atau lebih dari tindakan-tindakan berikut ini:<br>
          Melakukan Pemblokiran atas akun Anda.
          Melakukan Penutupan atas akun Anda.
          Melakukan Downgrade atas akun Anda.
          Menghentikan penyediaan Aplikasi RPT dan/atau Layanan RPT, baik untuk sebagian atau keseluruhan dan untuk sementara waktu atau secara permanen.
          Menghentikan akses Anda terhadap Produk Lainnya, baik untuk sebagian atau keseluruhan dan untuk sementara waktu atau secara permanen.
          Melakukan koreksi sebagaimana dimaksud pada BAB mengenai Koreksi, atau melakukan penahanan, penarikan kembali, dan pendebetan/pemotongan atas RPT Cash dan/atau RPT Points Anda untuk tujuan ganti rugi, apabila pelanggaran yang Anda lakukan menyebabkan kerugian material terhadap Kami, Pengguna RPT lainnya, dan/atau pihak lain manapun.
          Melakukan koreksi sebagaimana dimaksud pada BAB mengenai Koreksi, atau melakukan penahanan, penarikan kembali, dan/atau pendebetan/pemotongan atas saldo RPT Cash dan/atau saldo RPT Points yang Anda dapatkan secara tidak sah, dan membatalkan penyediaan layanan yang Anda dapatkan secara tidak sah, apabila pelanggaran yang Anda lakukan menyebabkan Anda secara tidak sah mendapatkan RPT Cash, RPT Points, dan/atau manfaat dari layanan-layanan apapun.
          Pemberian dan penerapan sanksi yang disebutkan dalam huruf a di atas bergantung pada tingkat keparahan atas pelanggaran yang dilakukan dan dampak yang timbul akibat dari pelanggaran tersebut
          Kami akan menginformasikan Anda perihal tindakan yang Kami ambil dalam rangka penindakan atas pelanggaran yang Anda lakukan atau yang terjadi dari akun Anda, melalui Aplikasi RPT, Media Komunikasi, atau Media Publikasi.<br><br>
          <strong class="fw-bold">L. PERNYATAAN DAN JAMINAN</strong><br><br>

          Anda dengan ini menyatakan dan menjamin bahwa:<br>
          Anda adalah individu yang cakap untuk melakukan tindakan hukum berdasarkan hukum yang berlaku di Republik Indonesia, khususnya untuk memberikan persetujuan atas Syarat dan Ketentuan ini; dan 
          Anda merupakan individu yang telah berusia 21 (dua puluh satu) tahun atau lebih, atau sudah menikah, dan Anda tidak berada di bawah pengampuan.
          Apabila Anda tidak memenuhi syarat kecakapan yang dimaksud dalam Angka 1 di atas, Anda menyatakan dan menjamin bahwa seluruh tindakan-tindakan yang Anda lakukan sehubungan dengan Aplikasi RPT, Layanan RPT, dan/atau Produk Lainnya telah disetujui oleh orang tua atau wali atau pengampu Anda yang sah.
          Anda menyatakan dan menjamin bahwa dana yang dipergunakan dalam rangka transaksi pembayaran, transfer dana, pengisian ulang RPT Cash, dan pembayaran-pembayaran Layanan RPT, Produk Lainnya, dan/atau biaya-biaya yang diberlakukan berdasarkan Syarat dan Ketentuan ini merupakan dana yang bersumber dari sumber yang sah dan bukan merupakan dana yang berasal dari tindakan-tindakan yang merupakan pencucian uang, pendanaan kegiatan terorisme, perjudian, dan tindakan-tindakan lainnya yang merupakan pelanggaran peraturan perundang-undangan yang berlaku di Republik Indonesia atau merupakan pelanggaran atas kepentingan pihak manapun.
          Anda menyatakan dan menjamin bahwa seluruh data dan informasi yang Anda sampaikan kepada Kami dan/atau afiliasi Kami untuk tujuan pendaftaran, upgrade, pengurusan klaim, dan tujuan-tujuan lainnya sehubungan dengan hubungan hukum yang terjadi antara Anda dan Kami merupakan data dan informasi yang valid, benar, dan akurat, serta penyampaian atau penyerahan data dan informasi tersebut kepada Kami bukanlah merupakan suatu pelanggaran atas peraturan perundang-undangan yang berlaku atau kepentingan pihak manapun.
          Anda menyatakan dan menjamin bahwa seluruh akses, penggunaan dan pemanfaatan yang Anda lakukan atas Aplikasi RPT, Layanan RPT, dan Produk Lainnya, dilakukan secara sah, dengan itikad baik, dan tidak melanggar peraturan perundang-undangan yang berlaku atau kepentingan pihak manapun.<br><br>
          <strong class="fw-bold">M. PEMBATASAN TANGGUNG JAWAB</strong><br><br>

          Penyampaian Informasi, Komunikasi, dan Korespondensi:<br>
          Anda memahami Kami dapat memberikan informasi-informasi yang penting bagi Anda atau melakukan komunikasi/korespondensi dengan Anda terkait Aplikasi RPT, Layanan RPT, dan/atau Produk Lainnya melalui Aplikasi RPT, Media Komunikasi, dan/atau Media Publikasi. 
          Anda memahami dan mengakui bahwa Anda bertanggung jawab sendiri dan penuh untuk:
          memperbarui kontak-kontak Anda dengan segera, jika Anda melakukan penggantian kontak-kontak yang telah Anda daftarkan kepada Kami;
          selalu memastikan bahwa kontak-kontak Anda yang Anda daftarkan kepada Kami adalah benar dan selalu aktif untuk tujuan penyediaan informasi-informasi kepada Anda; dan
          selalu mengecek secara rutin, membaca, dan mempelajari, informasi-informasi dan komunikasi/korespondensi yang Kami berikan melalui Aplikasi RPT, Media Komunikasi, ataupun Media Publikasi. 
          Kami tidak bertanggung jawab atas seluruh konsekuensi yang timbul akibat dari kelalaian atau kesalahan Anda dalam melaksanakan tanggung jawab Anda yang dimaksud dalam huruf b di atas.
          Data dan Informasi:<br>
          Anda dengan ini memahami dan mengakui bahwa Kami bertanggung jawab atas keamanan dan kerahasiaan atas:
          data dan/atau informasi yang tersimpan dalam sistem penyimpanan data Kami;
          data dan/atau informasi yang Kami kumpulkan dari Anda, baik melalui afiliasi Kami, petugas resmi Kami, ataupun mitra-mitra Kami yang Kami tunjuk untuk mengumpulkan data dan informasi Anda untuk kepentingan Kami; dan 
          data dan/atau informasi yang Kami kelola dan awasi untuk Anda dalam kapasitas Kami sebagai penyelenggara sistem elektronik.
          Terkait dengan huruf a di atas, Anda dengan ini memahami bahwa Anda bertanggung jawab sendiri dan penuh atas keamanan dan kerahasiaan atas:
          data dan/atau informasi yang berada dalam penguasaan dan/atau pengawasan Anda; 
          data dan/atau informasi yang disimpan oleh Anda dalam media apapun yang berada di bawah pengawasan Anda atau pihak ketiga; 
          data dan/atau informasi yang Anda serahkan kepada pihak ketiga tanpa melalui Kami;
          data dan/atau informasi yang dikumpulkan oleh pihak ketiga dari Anda bukan untuk kepentingan Kami atau bukan karena permintaan Kami;
          data dan/atau informasi yang tidak pernah Anda serahkan kepada Kami; dan 
          data dan/atau informasi Anda yang tidak pernah atau sudah tidak berada di bawah penguasaan dan pengawasan Kami. 
          Kami tidak bertanggung jawab atas konsekuensi yang timbul akibat dari: 
          kelalaian atau kesalahan Anda dalam menjaga keamanan dan kerahasiaan atas data dan/atau informasi Anda;
          pelanggaran keamanan dan kerahasiaan data dan/atau informasi yang dilakukan oleh pihak ketiga yang mengumpulkan dan/atau menyimpan data dan/atau informasi Anda bukan untuk kepentingan Kami atau bukan karena permintaan Kami; dan/atau
          pelanggaran keamanan dan kerahasiaan data dan/atau informasi yang bukan disebabkan secara langsung oleh; kelalaian atau kesalahan Kami dalam menjaga keamanan dan kerahasiaan data dan/atau informasi Anda, atau insiden keamanan kebocoran data dari sisi Kami. <br><br>
          Pembelian dan Pembayaran:<br>
          Anda memahami dan mengakui bahwa; setiap pembelian atau pembayaran yang Anda lakukan atas barang dan/atau jasa dari Merchant atau untuk pembayaran tagihan merupakan perbuatan hukum atau kesepakatan antara Anda dan Merchant. 
          Anda memahami dan mengakui bahwa; dalam hal Anda menggunakan RPT Cash dan/atau melakukan penukaran RPT Points dalam suatu transaksi pembelian atau pembayaran maka Kami hanya bertindak sebagai penyedia jasa pembayaran yang memfasilitasi pemrosesan transaksi pembelian atau pembayaran tersebut. 
          Anda memahami dan mengakui bahwa; seluruh hak dan kewajiban yang timbul dari perbuatan hukum atau kesepakatan antara Anda dan Merchant merupakan sepenuhnya tanggung jawab Anda dan Merchant. Oleh karena itu, Kami tidak bertanggung jawab atas penyediaan, pengiriman, kualitas, kuantitas, dan kepuasan ekspektasi barang dan/atau jasa yang dijual dan/atau disediakan oleh Merchant, dan pelaksanaan serta pemenuhan hak dan kewajiban yang timbul dari hubungan hukum atau kesepakatan antara Anda dan Merchant.
          Kepatuhan Terhadap Hukum Yang Berlaku: Anda bertanggung jawab penuh atas seluruh konsekuensi yang timbul dari pelanggaran hukum, dan pelanggaran kepentingan pihak manapun yang Anda lakukan. Kami tidak bertanggung jawab atas konsekuensi yang timbul dari pelanggaran peraturan perundang-undangan yang berlaku, atau pelanggaran kepentingan pihak lain yang Anda lakukan.
          Instrumen Pembayaran Lain: Anda dengan ini memahami dan mengakui bahwa Kami merupakan penyedia jasa pembayaran yang menerbitkan uang elektronik berbasis server yaitu RPT Cash, dan Kami hanya bertanggung jawab atas pemrosesan transaksi pembayaran yang dilakukan dengan menggunakan RPT Cash dan/atau dengan penukaran RPT Points. 
          Kode OTP dan Security Code:
          Anda dengan ini memahami bahwa kode OTP dan Security Code merupakan kode yang digunakan untuk mengakses akun Anda dan Aplikasi RPT, dan memanfaatkan Layanan RPT. Oleh karena itu, Anda wajib untuk selalu menjaga keamanan dan kerahasiaan kode OTP dan Security Code dengan selalu menerapkan dan melaksanakan Prinsip Kehati-hatian sebagaimana diatur dalam Syarat dan Ketentuan ini. 
          Kami, karyawan, perwakilan, petugas resmi Kami, dan afiliasi Kami tidak pernah dan tidak akan pernah meminta Anda untuk menyerahkan atau mengungkapkan kode OTP dan/atau Security Code Anda. Oleh karena itu, mohon untuk tidak merespon permintaan-permintaan dari pihak manapun yang meminta Anda untuk menyerahkan atau mengungkapkan kode OTP dan/atau Security Code Anda.
          Anda bertanggung jawab sendiri dan penuh atas keamanan dan kerahasiaan atas kode OTP, Security Code, dan perangkat elektronik Anda (ponsel atau komputer tablet), dan seluruh konsekuensi yang timbul dari kelalaian, kesalahan, atau kecerobohan Anda dalam menjaga keamanan dan kerahasiaan kode OTP, Security Code, dan perangkat Anda tersebut.
          Mohon dimengerti bahwa beberapa smartphone atau komputer tablet tertentu memiliki fitur biometric recognition yang dapat digunakan untuk menggantikan fungsi Security Code, yang mana fitur tersebut bukan merupakan fitur yang Kami kelola atau sediakan. Oleh karena itu, seluruh konsekuensi yang timbul dari penggunaan fitur tersebut merupakan sepenuhnya tanggung jawab Anda sendiri.
          Kesalahan Dalam Pemanfaatan Layanan:
          Kami sangat menghimbau Anda untuk selalu bersikap cermat dan teliti dalam mengakses akun Anda, memanfaatkan Aplikasi RPT, Layanan RPT, dan Produk Lainnya untuk menghindari risiko-risiko yang mungkin terjadi karena kelalaian atau kecerobohan Anda.
          Anda bertanggung jawab sendiri untuk memanfaatkan Aplikasi RPT, Layanan RPT, dan Produk Lainnya secara cermat dan teliti untuk menghindari risiko-risiko yang mungkin terjadi akibat dari kelalaian atau kecerobohan Anda, yaitu:<br>
          kegagalan pengaksesan akun Anda dan Aplikasi RPT atau terblokirnya akun Anda akibat dari kecerobohan atau kelalaian Anda dalam memasukan kode OTP dan/atau Security Code; 
          kegagalan atau kesalahan pelaksanaan transfer RPT Cash akibat dari kecerobohan atau kelalaian Anda dalam memasukan nomor ponsel penerima dana atau nomor rekening penerima dana;
          kesalahan pelaksanaan transaksi pembayaran akibat dari kecerobohan atau kelalaian Anda dalam memasukan nilai nominal transaksi pembayaran yang seharusnya;
          kegagalan pelaksanaan transaksi atau transfer RPT Cash akibat dari kecerobohan atau kelalaian Anda dalam memasukan Security Code atau nomor ponsel atau nomor rekening penerima dana;
          kegagalan atau kesalahan pelaksanaan pengisian ulang saldo RPT Cash (top up) akibat dari kecerobohan atau kelalaian Anda dalam memasukan nomor ponsel penerima pengisian ulang (top up);
          kekeliruan, kesalahpahaman, atau kesalahan dalam pembelian/pembayaran atas barang dan/atau jasa akibat dari kecerobohan atau kelalaian Anda dalam memahami barang dan/atau jasa yang Anda beli, syarat dan ketentuan yang Anda sepakati dengan Merchant terkait dengan pembelian/pembayaran, dan/atau dalam memilih dan menentukan barang dan/atau jasa yang Anda beli/bayarkan; dan/atau
          kegagalan, kesalahan, kekeliruan, atau kesalahpahaman lainnya yang disebabkan oleh kecerobohan atau kelalaian Anda dalam memanfaatkan Aplikasi RPT atau Layanan RPT atau Produk Lainnya secara cermat dan teliti.
          Jaringan Internet:<br>
          Anda dengan ini memahami dan mengakui bahwa pengaksesan dan pemanfaatan Aplikasi RPT, Layanan RPT, dan Produk Lainnya membutuhkan jaringan internet. Anda juga memahami bahwa gangguan pada jaringan internet dapat menyebabkan kegagalan, keterlambatan, dan pembatasan terhadap pengaksesan dan pemanfaatan Aplikasi RPT, Layanan RPT, dan Produk Lainnya.
          Anda dengan ini memahami dan mengakui bahwa ketersediaan dan kelancaran jaringan internet bukan merupakan tanggung jawab Kami.<br><br>
          Prinsip Kehati-hatian<br><br>
          Kami sangat menghimbau Anda untuk selalu menerapkan dan melaksanakan Prinsip Kehati-hatian, sebagaimana diatur dalam BAB tentang Risiko Penggunaan dalam Syarat dan Ketentuan ini, setiap Anda mengakses akun Anda, memanfaatkan Aplikasi RPT, Layanan RPT, dan Produk Lainnya, untuk menghindari risiko-risiko yang dapat mengancam keamanan akun Anda atau dapat merugikan Anda.
          Anda bertanggung jawab sendiri dan penuh dalam menerapkan dan melaksanakan Prinsip Kehati-hatian. Kami tidak dapat dimintakan pertanggungjawaban atas seluruh konsekuensi dan kerugian yang timbul akibat dari kelalaian atau kesalahan Anda dalam menerapkan Prinsip Kehati-hatian atau akibat dari kejadian-kejadian merugikan yang Anda alami yang tidak disebabkan secara langsung oleh kelalaian atau kesalahan Kami dalam menyediakan Aplikasi RPT dan/atau layanan-layanan Kami kepada Anda.<br><br>
          <strong class="fw-bold">N. SANGKALAN</strong><br><br>

          Kami tidak memberikan jaminan apapun bahwa Aplikasi RPT dapat diunduh atau digunakan pada semua perangkat elektronik, (komputer tablet atau smartphone), oleh karena itu, mohon untuk memastikan bahwa perangkat elektronik yang Anda gunakan kompatibel dengan Aplikasi RPT agar Anda dapat menggunakan Aplikasi RPT dan fitur-fitur di dalamnya. Penggunaan perangkat elektronik yang tidak memiliki kompatibilitas dengan Aplikasi RPT dapat menyebabkan Anda tidak dapat menggunakan Aplikasi RPT dan/atau fitur-fitur di dalamnya, atau menghambat penggunaan fitur-fitur pada Aplikasi RPT atau konsekuensi lainnya.
          Kami tidak memberikan jaminan apapun bahwa Aplikasi RPT dan/atau fitur-fitur di dalamnya dapat tetap beroperasi atau digunakan secara bersamaan dengan kombinasi perangkat keras, perangkat lunak, ataupun perangkat manapun atau sistem dari pihak manapun.<br><br>
          <strong-fw class="bold">O. PENAUTAN AKUN</strong-fw><br><br>

          Untuk kejelasan kata-kata berikut ini memiliki pengertian sebagai berikut:<br>
          "Penautan Akun" adalah penautan akun Pengguna RPT Anda dengan akun Anda lainnya yang terdaftar pada Platform Merchant yang Anda gunakan dengan cara memilih pilihan linking atau pilihan sejenisnya yang tersedia pada Platform Merchant yang Anda gunakan.
          "Platform Merchant" adalah platform dalam bentuk website atau mobile application yang ditujukan untuk memfasilitasi penawaran dan pembelian barang dan/atau jasa yang ditawarkan, dijual, dan/atau disediakan oleh Merchant.
          Penautan Akun diperlukan agar Anda dapat mengakses satu atau lebih dari Layanan RPT pada Platform Merchant tanpa harus membuka Aplikasi RPT. Beberapa Layanan RPT yang dapat Kami sediakan melalui Platform Merchant, yaitu; layanan transaksi pembayaran dengan RPT Cash dan/atau penukaran RPT Points, pengecekan saldo RPT Cash dan RPT Points, dan layanan pengecekan riwayat transaksi yang dilakukan pada Platform Merchant, dan layanan-layanan lainnya sebagaimana Kami kembangkan dan sediakan di kemudian hari untuk Anda. 
          Ketersediaan Layanan RPT pada satu Platform Merchant dengan Platform Merchant lainnya dapat berbeda, hal ini bergantung pada kesepakatan Kami dengan Merchant yang merupakan pemilik/pengelola Platform Merchant terkait atau bergantung pada kebijakan internal Kami. Perlu diketahui bahwa Penautan Akun hanya dapat dilakukan pada Platform Merchant-Platform Merchant yang telah Kami tunjuk untuk dapat menerima pelaksanaan Penautan Akun. Kami tidak bertanggungjawab atas seluruh konsekuensi yang timbul dari Penautan Akun yang Anda lakukan pada platform manapun yang tidak dikelola oleh pihak-pihak yang Kami tunjuk untuk menerima fitur Penautan Akun. Mohon untuk melakukan pengecekan melalui Layanan Bantuan Pengguna untuk mengetahui platform-platform yang dapat menerima Penautan Akun yang dikelola oleh pihak-pihak yang Kami tunjuk.
          Dengan dilakukannya Penautan Akun maka informasi yang relevan terhadap transaksi pembayaran RPT Cash atau penukaran RPT Points yang Anda lakukan pada Platform Merchant, yaitu; riwayat transaksi, dan status transaksi, dan saldo RPT Cash dan RPT Points Anda, atau informasi lainnya yang relevan terkait dengan pemanfaatan Layanan RPT pada Platform Merchant, mungkin akan dapat terlihat pada Platform Merchant tersebut dan dapat dilihat oleh Merchant yang mengelola Platform Merchant tersebut. 
          Keterbukaan atas informasi-informasi dimaksud dalam Angka 4 di atas dibutuhkan untuk tujuan penyediaan Layanan RPT melalui Platform Merchant. Dengan melakukan Penautan Akun berarti Anda telah sepenuhnya setuju untuk memberikan Kami izin dan wewenang untuk memperlihatkan dan mengirimkan informasi-informasi dimaksud kepada Merchant untuk tujuan tersebut.
          Pengakhiran Penautan Akun:<br>
          Anda dapat menghentikan Penautan Akun Anda setiap saat, dengan melakukan pengakhiran Penautan Akun atau unlinking, melalui pilihan yang tersedia pada Platform Merchant yang Anda gunakan.
          Dengan melakukan unlinking maka Penautan Akun akan diakhiri dan Layanan RPT yang tersedia pada Platform Merchant akan menjadi tidak dapat digunakan pada Platform Merchant tersebut sampai dengan Anda melakukan Penautan Akun kembali.
          Anda dapat mempelajari dan memahami tata cara Penautan Akun, pengakhiran Penautan Akun (unlinking), dan pemanfaatan Layanan RPT secara langsung pada Platform Merchant dimana layanan Penautan Akun, pengakhiran Penautan Akun, dan Layanan RPT tersedia.<br><br>
          <strong class="fw-bold">P. TRANSAKSI BERULANG (RECURRING TRANSACTIONS)</strong><br><br>

          Untuk kejelasan kata-kata berikut ini memiliki pengertian sebagai berikut:<br>
          "Penyedia Produk Berlangganan" atau "Penyedia" adalah Merchant atau pihak ketiga yang menjual dan/atau menyediakan Produk Berlangganan.
          "Produk Berlangganan" adalah barang, jasa, dan/atau tagihan lainnya yang dibeli/dibayarkan secara berlangganan atau terus menerus selama jangka waktu tertentu atau selama Anda masih setuju untuk berlangganan barang dan/atau jasa atau pembayaran tagihan tersebut.
          "Transaksi Berulang (Recurring Transaction)" adalah transaksi pembayaran yang dilakukan secara otomatis dan berulang pada setiap tanggal jatuh tempo atau dalam periode jatuh tempo yang telah disepakati oleh dan antara Anda dan Penyedia untuk tujuan pembayaran Produk Berlangganan atau pembayaran biaya-biaya lainnya yang telah disepakati oleh dan antara Anda dan Penyedia.
          Pembelian/pembayaran atas Produk Berlangganan menyebabkan Penyedia dapat mengenakan Anda dengan biaya-biaya atas penyediaan Produk Berlangganan yang wajib Anda bayarkan setiap tanggal jatuh tempo atau dalam periode jatuh tempo pembayaran yang Anda sepakati dengan Penyedia tersebut. Oleh karena itu, mohon untuk memperhatikan setiap pembelian/pembayaran yang Anda lakukan.
          Keberhasilan atas pelaksanaan pembelian/pembayaran atas Produk Berlangganan untuk pertama kalinya dalam satu proses transaksi pembayaran yang Kami proses ("Transaksi Pertama") akan menyebabkan Kami untuk melakukan pemrosesan Transaksi Berulang pada setiap tanggal jatuh tempo atau dalam periode jatuh tempo pembayaran yang Anda sepakati dengan Penyedia.
          Transaksi Pertama untuk pembayaran/pembelian Produk Berlangganan dapat dilakukan setelah Anda melakukan Penautan Akun. Setelah Anda berhasil melakukan Transaksi Pertama maka untuk selanjutnya Kami akan secara otomatis melakukan pemrosesan atas Transaksi Berulang pada setiap tanggal jatuh tempo atau dalam periode jatuh tempo pembayaran atas Produk Berlangganan. Dengan Anda memberikan konfirmasi dan otorisasi untuk pelaksanaan Transaksi Pertama berarti Anda telah memberikan persetujuan:
          kepada Penyedia untuk pembayaran/pembelian Produk Berlangganan dan/atau biaya-biaya lainnya sebagaimana Anda sepakati dengan Penyedia tersebut yang mana pembayaran/pembelian tersebut akan dilakukan secara otomatis dan berulang pada setiap tanggal jatuh tempo atau dalam periode jatuh tempo pembayaran yang telah disepakati oleh Anda dan Penyedia; dan
          kepada RPT untuk memproses Transaksi Berulang dengan memotong/mendebet saldo RPT Cash Anda secara otomatis untuk tujuan pembayaran-pembayaran yang dimaksud dalam huruf a di atas pada setiap tanggal jatuh tempo atau dalam periode jatuh tempo pembayaran yang telah disepakati oleh Anda dan Penyedia.
          Untuk kejelasan, pemotongan/pendebetan saldo RPT Cash dalam Transaksi Berulang akan dilaksanakan secara otomatis tanpa proses konfirmasi dan otorisasi ulang dari Anda, mengingat konfirmasi dan otorisasi atas pelaksanaan Transaksi Berulang telah Anda berikan pada saat Anda melakukan Transaksi Pertama. Transaksi Pertama dan Transaksi Berulang hanya dapat diproses dengan menggunakan RPT Cash, dan tidak bisa digantikan dengan penukaran RPT Points atau dikombinasikan dengan penukaran RPT Points.
          Pengakhiran Penautan Akun:<br>
          Apabila Anda mengakhiri Penautan Akun Anda maka Kami tidak akan dapat memproses Transaksi Berulang untuk tanggal jatuh tempo atau periode jatuh tempo pembayaran Produk Berlangganan dimana Anda belum kembali lagi melakukan Penautan Akun. 
          Apabila Anda mengakhiri Penautan Akun pada Platform Merchant dimana Anda masih memiliki masa berlangganan yang masih aktif/valid maka terdapat kemungkinan bahwa Anda tidak akan dapat melakukan Penautan Akun kembali pada Platform Merchant sampai dengan tanggal jatuh tempo atau periode jatuh tempo pembayaran atas Produk Berlangganan tersebut terlampaui atau sampai dengan masa berlangganan Anda habis. Prosedur Penautan Akun kembali saat Anda masih memiliki masa berlangganan yang masih aktif/valid bergantung pada prosedur berlangganan yang diterapkan oleh Penyedia.<br><br>
          Ketentuan dalam huruf a dan b di atas tidak berlaku, dalam hal Kami mengecualikan persyaratan untuk melakukan Penautan Akun untuk dapat melakukan Transaksi Pertama dan Transaksi Berulang dalam pembelian/pembayaran Produk Berlangganan tertentu.
          Kecukupan Saldo RPT Cash:<br>
          Anda bertanggung jawab sendiri untuk memastikan bahwa saldo RPT Cash Anda cukup untuk dipotong/didebet untuk tujuan pemrosesan Transaksi Pertama, dan pemrosesan Transaksi Berulang pada setiap tanggal jatuh tempo atau dalam periode jatuh tempo pembayaran. 
          Apabila pada tanggal jatuh tempo atau dalam periode jatuh tempo pembayaran, Anda tidak memiliki saldo RPT Cash yang cukup untuk pelaksanaan Transaksi Berulang maka Kami tidak akan dapat memproses Transaksi Berulang. <br><br>
          Tidak diprosesnya Transaksi Berulang dapat menyebabkan berakhirnya atau tertundanya penyediaan Produk Berlangganan dari Penyedia kepada Anda.
          Pengakhiran Langganan:<br>
          Pengakhiran langganan atas Produk Berlangganan dapat Anda ajukan kepada Penyedia.
          Pengakhiran langganan sepenuhnya bergantung pada kesepakatan antara Anda dengan Penyedia dan pelaksanaannya tunduk pada kesepakatan tersebut.
          Apabila Anda mengakhiri kesepakatan berlangganan Anda dengan Penyedia maka Kami akan berhenti memproses Transaksi Berulang untuk tanggal jatuh tempo dan periode jatuh tempo berikutnya setelah Kami mendapatkan konfirmasi dari Penyedia mengenai pengakhiran berlangganan tersebut.
          Kami akan berhenti memproses Transaksi Berulang, apabila salah satu atau lebih kondisi berikut ini terjadi:
          Penautan Akun Anda berakhir.<br><br>
          Kami tidak lagi mendapatkan instruksi untuk melakukan pemotongan/pendebetan dari Penyedia.
          Kami mendapatkan konfirmasi dari Penyedia bahwa langganan Anda atas Produk Berlangganan telah berakhir atau dihentikan.
          Penyedia menahan atau menghentikan langganan Anda.
          Anda tidak memiliki saldo RPT Cash yang cukup untuk dipotong/didebit untuk Transaksi Berulang pada tanggal jatuh tempo atau dalam periode jatuh tempo pembayaran atas Produk Berlangganan. Atas penghentian pemrosesan Transaksi Berulang karena alasan ini, pemrosesan Transaksi Berulang mungkin dapat dilanjutkan apabila Anda kembali memiliki saldo RPT Cash yang cukup untuk dipotong/didebet dan sepanjang salah satu atau lebih dari kondisi dalam huruf a-d di atas tidak terjadi.
          Anda dengan ini mengerti dan memahami bahwa dalam pembelian/pembayaran Produk Berlangganan, Kami hanya bertindak sebagai penyedia jasa pembayaran yang bertanggung jawab dalam pemrosesan Transaksi Pertama dan Transaksi Berulang berdasarkan konfirmasi dan otorisasi dari Anda pada Transaksi Pertama. Kesepakatan antara Anda dengan Penyedia terkait dengan pembelian/pembayaran dan penyediaan Produk Berlangganan merupakan sepenuhnya tanggung jawab Anda dan Penyedia. Sehubungan dengan hal ini dan untuk menghindari kesalahpahaman dalam pelaksanaan Transaksi Berulang, Kami sangat menghimbau Anda untuk mencermati, mempelajari, dan memahami:
          biaya-biaya yang akan dibebankan kepada Anda oleh Penyedia dalam rangka pembelian/pembayaran Produk Berlangganan; 
          tanggal jatuh tempo dan periode jatuh tempo pembayaran atas biaya-biaya tersebut yang diterapkan oleh Penyedia;
          syarat dan ketentuan yang mengikat Anda dengan Penyedia;
          ketentuan mengenai pengakhiran berlangganan yang diterapkan oleh Penyedia; dan
          persetujuan-persetujuan yang Anda berikan dalam pembelian/pembayaran Produk Berlangganan.
          Kami dapat mengecualikan persyaratan untuk melakukan Penautan Akun untuk dapat melakukan Transaksi Pertama dan Transaksi Berulang dalam pembelian/pembayaran Produk Berlangganan tertentu. Dalam hal Kami mengecualikan persyaratan Penautan Akun untuk pelaksanaan Transaksi Pertama dan Transaksi Berulang maka seluruh ketentuan-ketentuan dalam BAB ini yang mengatur mengenai Penautan Akun akan menjadi tidak berlaku.<br><br>
          <strong class="fw-bold">Q. LAYANAN PEMBAYARAN</strong><br><br>

          Perlu Anda ketahui beberapa jenis Layanan RPT dapat diakses melalui layanan pembayaran yang disediakan pihak ketiga yang merupakan penyedia layanan penunjang pembayaran ataupun penyedia jasa pembayaran lainnya ("Layanan Pembayaran").
          Dalam hal Anda mengakses dan menggunakan Layanan RPT melalui Layanan Pembayaran dari pihak ketiga Anda mungkin akan diminta untuk memberikan persetujuan Anda terhadap syarat dan ketentuan terkait dengan Layanan Pembayaran yang disediakan oleh pihak ketiga tersebut sebelum atau pada saat Anda mengakses dan menggunakan Layanan RPT melalui Layanan Pembayaran dari pihak ketiga tersebut.
          Dalam hal Anda mengakses dan menggunakan Layanan RPT melalui Layanan Pembayaran dari pihak ketiga, Kami mungkin harus mengungkapkan dan mengirimkan data dan informasi terkait pemanfaatan Layanan RPT Anda yaitu; nomor ponsel akun Pengguna RPT Anda, saldo RPT Cash, saldo RPT Points, data transaksi pembayaran RPT Cash atau data penukaran RPT Points Anda, dan/atau data-data lainnya sebagaimana relevan terkait dengan pemanfaatan Layanan RPT Anda, kepada pihak ketiga dimaksud untuk tujuan penyediaan Layanan RPT melalui Layanan Pembayaran dari pihak ketiga tersebut. 
          Anda dengan ini mengerti dan memahami bahwa dengan Anda mengakses dan menggunakan Layanan RPT melalui Layanan Pembayaran dari pihak ketiga berarti Anda telah memberikan persetujuan Anda kepada Kami untuk mengungkapkan dan mengirimkan data dan informasi sebagaimana disebutkan dalam Angka 3 di atas kepada pihak ketiga dimaksud untuk tujuan penyediaan Layanan RPT melalui Layanan Pembayaran dari pihak ketiga tersebut.
          Mohon untuk berhati-hati dalam menggunakan Layanan Pembayaran dari pihak ketiga. Pastikan bahwa Layanan Pembayaran yang Anda gunakan disediakan oleh pihak ketiga yang merupakan mitra resmi Kami. Anda dapat menanyakan perihal ini ke Layanan Bantuan Pengguna. Kami tidak dapat dimintakan pertanggungjawaban atas seluruh konsekuensi yang timbul dari penggunaan Layanan RPT melalui Layanan Pembayaran yang tidak disediakan oleh mitra resmi Kami.<br><br>
          <strong class="fw-bold">R. RISIKO PENGGUNAAN</strong><br><br>

          Dengan mengunduh dan menggunakan Aplikasi RPT, Anda dianggap telah mengerti dan memahami seluruh risiko-risiko keamanan dalam penggunaan Aplikasi RPT, Layanan RPT, dan Produk Lainnya, yaitu; pencurian saldo, pengambilalihan akun, peretasan, penipuan, atau tindakan-tindakan lainnya yang merupakan pelanggaran hukum yang dapat merugikan Anda. Berkaitan dengan risiko tersebut Kami sangat menghimbau Anda untuk selalu menerapkan Prinsip Kehati-hatian.
          Prinsip Kehati-hatian dapat diterapkan dan dilaksanakan dengan cara-cara berikut:
          Tidak menyerahkan, mengungkapkan, dan/atau mengirimkan kode OTP dan/atau Security Code Anda kepada pihak manapun. Mohon diingat bahwa Kami, petugas resmi, dan afiliasi Kami tidak pernah dan tidak akan pernah meminta Anda untuk mengungkapkan dan menyerahkan kode OTP dan/atau Security Code kepada Anda.
          Tidak merespon segala bentuk kontak, baik panggilan telepon, email, atau tautan, yang mencurigakan dan mengklaim berasal dari Kami. Mohon untuk mengecek kebenaran dari kontak-kontak yang menghubungi Anda yang mengklaim berasal dari Kami, kepada Layanan Bantuan Pengguna.
          Selalu menjaga keamanan dan kerahasiaan kode OTP dan Security Code Anda, perangkat elektronik Anda, dan data dan informasi Anda, terutama yang berkaitan dengan data pribadi Anda.
          Tidak menggunakan perangkat lunak atau program apapun pada perangkat elektronik Anda yang dapat membahayakan keamanan dan kerahasiaan data dan informasi Anda.
          Tidak mengakses akun dan Aplikasi RPT dari perangkat elektronik yang tidak dimiliki atau dikuasai oleh Anda.
          Selalu menghilangkan jejak atas akses akun Anda pada perangkat elektronik yang tidak lagi Anda gunakan.
          Selalu menjaga keamanan perangkat elektronik yang digunakan untuk mengakses akun Anda agar tidak hilang atau jatuh ke tangan pihak yang tidak bertanggung jawab.
          Segera menghubungi Layanan Bantuan Pengguna, jika Anda mengalami kehilangan atas perangkat elektronik yang digunakan untuk mengakses akun Anda.
          Segera menghubungi Layanan Bantuan Pengguna, jika Anda menemukan adanya indikasi atas akses yang tidak sah atas akun Anda.
          Segera dan hanya menghubungi Layanan Bantuan Pengguna untuk meminta bantuan apapun terkait Aplikasi RPT atau Layanan RPT.
          Selalu melakukan update perangkat lunak Aplikasi RPT, jika terdapat permintaan update dari Aplikasi RPT.
          Melakukan pengecekan secara berkala atas informasi dan pemberitahuan yang disediakan melalui Aplikasi RPT, Media Komunikasi, atau Media Publikasi.
          Selalu memeriksa kebenaran atas setiap pihak-pihak yang mengaku bertindak untuk dan atas nama Kami (PT Visionet Internasional) dan/atau afiliasi Kami. Anda dapat menghubungi Layanan Bantuan Pengguna untuk memeriksa hal yang dimaksud dalam ketentuan ini.
          Selalu memeriksa kebenaran atas setiap platform, website, aplikasi yang meng-klaim merupakan kanal elektronik resmi Kami. Anda dapat menghubungi Layanan Bantuan Pengguna untuk memeriksa hal yang dimaksud dalam ketentuan ini.
          Tidak mengungkapkan informasi-informasi yang hanya boleh diketahui oleh Anda kepada pihak manapun, yaitu; data pribadi, data transaksi, kode OTP dan Security Code Anda.
          Tidak mengunduh atau menggunakan program-program ataupun menggunakan perangkat elektronik yang mengandung malware, spyware, virus, dan program-program lainnya yang dapat membahayakan keamanan dan kerahasiaan data dan informasi Anda, ataupun akun Anda.
          Selalu mengganti Security Code Anda secara berkala dan teratur untuk menghindari risiko-risiko yang berhubungan dengan akses tidak sah.
          Segera mengganti Security Code Anda, jika Security Code tersebut diketahui oleh atau Anda menduga bahwa Security Code tersebut diketahui oleh pihak yang tidak berwenang.
          Selalu memperhatikan situasi di sekitar Anda saat Anda mengakses akun Anda atau memasukan kode OTP dan/atau Security Code dan menghindari lingkungan yang mencurigakan pada saat Anda mengakses akun Anda atau memasukan kode OTP dan/atau Security Code.
          Tidak membiarkan pihak manapun untuk mengakses/menggunakan perangkat elektronik Anda atau menggunakan akun atau Aplikasi RPT Anda.
          Hanya menggunakan kanal-kanal resmi Kami dalam mengakses dan menggunakan Layanan RPT atau Penautan Akun. Anda dapat menghubungi Layanan Bantuan Pengguna untuk mendapatkan informasi mengenai kanal-kanal resmi Kami. 
          Hindari menggunakan jaringan wifi publik atau wifi pada tempat umum untuk mengakses atau menggunakan akun Anda dan RPT App.
          Selalu melakukan tindakan-tindakan yang sewajarnya dilakukan untuk mencegah risiko-risiko yang dapat merugikan Anda. 
          Risiko Pengambilalihan Akun:
          Perlu diketahui bahwa setiap akses ke akun Anda dan Aplikasi RPT dan/atau pemanfaatan Layanan RPT dan/atau layanan-layanan yang tersedia melalui Aplikasi RPT, yang dilakukan dengan memasukan nomor ponsel, kode OTP dan/atau Security Code yang benar, akan diakui dan dianggap pada sistem keamanan Kami sebagai akses dan pemanfaatan yang diri Anda lakukan sendiri.
          Anda dengan ini memahami bahwa Kami tidak dapat mengetahui secara pasti pihak-pihak yang mengakses akun Anda sepanjang nomor ponsel, kode OTP, dan/atau Security Code yang dimasukan pada Aplikasi RPT adalah benar (otentikasi valid), Oleh karena itu, Kami kembali lagi menghimbau Anda untuk selalu menerapkan dan melaksanakan Prinsip Kehati-hatian dan menjaga keamanan dan kerahasiaan kode OTP dan Security Code Anda untuk menghindari risiko pengambilalihan akun, dan tidak membiarkan pihak manapun untuk menggunakan perangkat elektronik, akun Anda, dan Aplikasi RPT Anda. 
          Dalam hal Anda menyadari telah terjadi pengambilalihan atas akun Anda atau Anda mencurigai terjadinya pengambilalihan atas akun Anda, Anda harus dengan segera menghubungi Layanan Bantuan Pengguna, untuk meminta pemblokiran sementara atas akun Anda untuk mencegah terjadinya risiko-risiko yang dapat merugikan Anda. 
          Anda dengan ini sepakat dan memahami bahwa Anda tidak dapat mengajukan klaim ganti rugi kepada Kami atas kerugian yang Anda alami dari pengambilalihan akun atas akun Anda.<br><br>
          <strong class="fw-bold">S. PENGHENTIAN LAYANAN</strong><br><br>

          Kami berhak untuk menghentikan penyediaan Aplikasi RPT dan/atau Layanan RPT, secara sebagian atau secara keseluruhan dan untuk jangka waktu tertentu atau secara permanen, karena terjadinya salah satu atau lebih dari ketentuan-ketentuan berikut ini:<br>
          Terjadi Pemblokiran atau Penutupan akun.
          Terjadi pelanggaran atas salah satu atau lebih ketentuan-ketentuan dalam Syarat dan Ketentuan ini, baik yang Anda lakukan atau yang terjadi dari akun Anda.
          Terjadi kerusakan, gangguan, dan/atau peretasan, pada sistem operasi Kami yang menyebabkan Kami harus menghentikan penyediaan Layanan RPT.
          Terdapat indikasi atas terjadinya suatu transaksi atau aktivitas yang mencurigakan yang patut diduga merupakan suatu tindakan pencucian uang, pendanaan kegiatan terorisme, atau tindakan pelanggaran atas peraturan perundang-undangan yang berlaku, yang dapat merugikan Kami, Pengguna RPT lainnya, diri Anda sendiri, dan/atau pihak lain manapun.
          Kami mendapatkan perintah dari otoritas atau instansi yang berwenang untuk menghentikan sebagian atau seluruh aktivitas penyelenggaraan uang elektronik Kami dan/atau Aplikasi RPT yang mengakibatkan Kami tidak dapat menyediakan Aplikasi RPT atau Layanan RPT, baik sebagian atau secara keseluruhan.
          Anda berada di luar wilayah Negara Kesatuan Republik Indonesia sehingga dapat menyebabkan sebagian atau seluruh Aplikasi RPT atau Layanan RPT menjadi tidak dapat disediakan.
          Terjadinya suatu Keadaan Kahar yang menyebabkan Kami tidak dapat menyediakan Aplikasi RPT dan/atau Layanan RPT, baik sebagian atau secara keseluruhan.
          Ketentuan Penghentian:<br>
          Penghentian penyediaan Aplikasi RPT dan/atau Layanan RPT karena alasan Pemblokiran akun akan berakhir setelah akun diaktifkan kembali.
          Penghentian penyediaan Aplikasi RPT dan/atau Layanan RPT karena alasan Penutupan akun merupakan penghentian yang dilaksanakan secara permanen.
          Penghentian penyediaan Aplikasi RPT dan/atau Layanan RPT karena alasan pelanggaran Syarat dan Ketentuan ini dapat dilaksanakan untuk jangka waktu tertentu ataupun secara permanen bergantung pada tingkat keparahan atas pelanggaran yang dilakukan dan dampak yang timbul akibat dari pelanggaran tersebut.
          Penghentian penyediaan Aplikasi RPT dan/atau Layanan RPT karena alasan Angka 1 huruf c pada BAB ini akan berakhir setelah Kami berhasil memperbaiki kerusakan, gangguan, dan/atau peretasan yang dimaksud dalam Angka 1 huruf c pada BAB ini.
          Penghentian penyediaan Aplikasi RPT dan/atau Layanan RPT karena alasan Angka 1 huruf d pada BAB ini akan berakhir, jika indikasi/dugaan yang dimaksud tidak terbukti.
          Penghentian penyediaan Aplikasi RPT dan/atau Layanan RPT karena alasan Angka 1 huruf d pada BAB ini akan dilaksanakan secara permanen, jika indikasi/dugaan yang dimaksud terbukti berdasarkan hasil investigasi Kami atau hasil investigasi otoritas yang berwenang.
          Penghentian penyediaan Aplikasi RPT dan/atau Layanan RPT karena alasan Angka 1 huruf e pada BAB ini akan berakhir, jika Kami kembali diperbolehkan untuk melaksanakan kembali aktivitas penyelenggaraan uang elektronik Kami atau Aplikasi RPT oleh otoritas atau instansi yang berwenang, terutama yang terkait dengan penyediaan Layanan RPT.
          Penghentian penyediaan Aplikasi RPT dan/atau Layanan RPT karena alasan Angka 1 huruf f pada BAB ini akan berakhir setelah Anda kembali ke Indonesia. Perlu diketahui bahwa kemungkinan sebagian atau seluruh Aplikasi RPT atau Layanan RPT akan menjadi tidak dapat digunakan di luar wilayah Negara Kesatuan Republik Indonesia.
          Penghentian penyediaan Aplikasi RPT dan/atau Layanan RPT karena alasan Angka 1 huruf g pada BAB ini akan berakhir setelah Kami berhasil memulihkan dampak Keadaan Kahar yang menyebabkan Kami tidak dapat menyediakan Aplikasi RPT dan/atau Layanan RPT.
          Dalam hal terjadi penghentian penyediaan layanan yang dimaksud dalam BAB ini maka Kami akan menginformasikan Anda mengenai penghentian tersebut melalui Aplikasi RPT, Media Komunikasi, atau Media Publikasi 
          Khusus, untuk penghentian penyediaan Aplikasi RPT dan/atau Layanan RPT karena alasan yang dimaksud dalam Angka 1 huruf f, Aplikasi RPT Anda akan secara otomatis tidak berfungsi, baik sebagian atau seluruhnya, pada saat Anda berada di luar wilayah Negara Kesatuan Republik Indonesia. Penghentian penyediaan Aplikasi RPT dan/atau Layanan RPT karena alasan yang dimaksud dalam Angka 1 huruf f dikecualikan dari ketentuan pemberian informasi sebagaimana dimaksud dalam Angka 3 di atas.
          Dalam hal terjadi penghentian penyediaan layanan yang dimaksud dalam BAB ini, Anda dapat menghubungi Layanan Bantuan Pengguna untuk memperoleh informasi lebih lanjut mengenai penghentian penyediaan layanan yang Anda alami.<br><br>
          <strong class="fw-bold">T. HAK ATAS KEKAYAAN INTELEKTUAL</strong><br><br>

          Kecuali dinyatakan lain secara tegas oleh Kami; Aplikasi RPT, sistem pendukungnya, sistem pengoperasian, layout, desain, tampilan dan perjalanan antar-muka, logo, gambar, nama, merek, dan seluruh bagian lainnya dari Aplikasi RPT merupakan hak kekayaan intelektual Kami.
          Anda dilarang untuk menyalin, memodifikasi, mengadaptasi, membuat karya turunan dari, mendistribusikan, menjual, mengalihkan, menampilkan di muka umum, membuat ulang, mentransmisikan, memindahkan, menyiarkan, menguraikan, membongkar, meretas dan mengeksploitasi Aplikasi RPT, bagian daripadanya, dan hak kekayaan intelektual yang terkandung di dalamnya.<br><br>
          <strong class="fw-bold">U. KEADAAN KAHAR</strong><br><br>

          "Keadaan Kahar" adalah keadaan atau kondisi yang terjadi di luar kemampuan pihak yang mengalaminya untuk mencegahnya dan tidak dapat dihindarkan oleh pihak yang mengalaminya, sehingga menyebabkan pihak yang mengalaminya tidak dapat melaksanakan seluruh atau sebagian kegiatannya yang berpengaruh terhadap pelaksanaan hak dan kewajiban dari pihak yang mengalaminya. Kejadian-kejadian yang termasuk ke dalam Keadaan Kahar, yaitu; bencana alam, kebakaran, banjir, kondisi perang, baik yang dinyatakan atau tidak, sabotase, pemogokan, demonstrasi, kerusuhan sosial, epidemi atau pandemi, diundangkannya suatu peraturan perundang-undangan, atau kondisi atau kejadian yang secara wajar tidak dapat dicegah oleh kemampuan pihak yang mengalaminya, yang menyebabkan pihak yang mengalaminya tidak dapat melaksanakan hak dan kewajiban yang timbul dari Syarat dan Ketentuan ini.
          Anda dengan ini sepakat bahwa kegagalan atau keterlambatan Kami dalam melaksanakan kewajiban-kewajiban dan/atau tanggung jawab Kami yang disebabkan oleh terjadinya suatu Keadaan Kahar tidak dapat dianggap sebagai suatu kejadian kelalaian atau wanprestasi.
          Anda dengan ini sepakat untuk tidak akan mengajukan gugatan, tuntutan, klaim dan/atau meminta ganti kerugian atas keterlambatan atau kegagalan Kami dalam melaksanakan dan memenuhi kewajiban-kewajiban Kami yang disebabkan oleh suatu Keadaan Kahar.<br><br>
          <strong class="fw-bold">V. HUKUM YANG BERLAKU DAN PENYELESAIAN PERSELISIHAN</strong><br><br>

          Seluruh ketentuan-ketentuan yang diatur dalam Syarat dan Ketentuan ini diatur dan ditafsirkan berdasarkan hukum yang berlaku di Negara Kesatuan Republik Indonesia.
          Anda dengan ini sepakat bahwa seluruh perselisihan yang timbul dari Syarat dan Ketentuan ini akan diselesaikan secara musyawarah untuk mencapai suatu kemufakatan.
          Anda dan Kami, secara sendiri-sendiri, juga berhak untuk mengajukan penyelesaian atas perselisihan ke pengadilan negeri Jakarta Selatan dan Bank Indonesia.<br><br>
          <strong class="fw-bold">W. MASA BERLAKU</strong><br><br>

          Seluruh ketentuan-ketentuan dalam Syarat dan Ketentuan ini mulai berlaku sejak Anda melakukan pendaftaran untuk menjadi Pengguna RPT dan akan tetap berlaku:
          selama Anda mengakses dan menggunakan Aplikasi RPT dan/atau Layanan RPT; dan/atau
          selama Anda masih terdaftar sebagai Pengguna RPT atau Anda masih memiliki akun Pengguna RPT yang belum dinonaktifkan secara permanen (ditutup secara permanen).
          Anda dengan ini sepakat untuk mengesampingkan ketentuan dalam Pasal 1266 Kitab Undang-Undang Hukum Perdata sepanjang mengenai dibutuhkannya suatu putusan atau penetapan pengadilan untuk mengakhiri suatu perjanjian. Sehingga pengakhiran hubungan hukum antara Anda dan Kami berdasarkan Syarat dan Ketentuan ini dapat berakhir atau diakhiri tanpa memerlukan suatu putusan atau penetapan dari pengadilan.<br><br>
          <strong class="fw-bold">X. LAYANAN BANTUAN PENGGUNA</strong><br><br>

          Seluruh permintaan informasi, permintaan layanan, pengaduan dan klaim terkait dengan akun Anda, Aplikasi RPT, Layanan RPT, dan Syarat dan Ketentuan ini wajib disampaikan hanya melalui Layanan Bantuan Pengguna. Layanan Bantuan Pengguna dapat dihubungi melalui kontak-kontak resmi Kami sebagai berikut:
          telepon ke nomor 1 500 696;
          email ke alamat email cs@rpt.id; atau
          pusat bantuan yang tersedia pada Aplikasi RPT.
          Untuk pertanyaan dan pengaduan yang tidak bersifat privat, Anda juga dapat menyampaikan-nya melalui sosial media resmi Kami yang dapat Anda tanyakan pada kontak-kontak yang disebutkan Kami sebagaimana dimaksud dalam Angka 1 huruf a atau b di atas.
          Anda dapat menanyakan mitra-mitra dan kanal-kanal layanan resmi Kami; kanal pendaftaran, kanal upgrade, mitra dan kanal top-up, dan mitra dan kanal tarik tunai resmi Kami melalui Layanan Bantuan Pengguna sebagaimana dimaksud dalam BAB ini.<br><br>
          <strong class="fw-bold">Y. PERUBAHAN</strong><br><br>

          Kami dapat meninjau dan mengubah Syarat dan Ketentuan ini atas pertimbangan Kami untuk memastikan bahwa ketentuan-ketentuan dalam Syarat dan Ketentuan ini konsisten dengan perkembangan Kami di masa depan, dan/atau jika terdapat perubahan peraturan perundang-undangan yang berlaku dan/atau perintah dari otoritas atau instansi yang berwenang atau peraturan perundang-undangan yang berlaku yang memerintahkan atau mewajibkan Kami untuk mengubah ketentuan-ketentuan dalam Syarat dan Ketentuan ini.
          Kami akan menginformasikan Anda mengenai perubahan sebagaimana dimaksud dalam Angka 1 di atas melalui Aplikasi RPT, Media Komunikasi, atau Media Publikasi paling lambat 30 (tiga puluh) hari kerja sebelum perubahan tersebut berlaku secara efektif, dan perubahan dimaksud akan dilaksanakan sesuai dengan ketentuan-ketentuan yang diberlakukan oleh Bank Indonesia khususnya mengenai perlindungan konsumen sebagaimana relevan.
          Anda bertanggung jawab sendiri untuk selalu dan secara rutin memeriksa, membaca, dan mempelajari informasi-informasi yang Kami sampaikan kepada Anda, baik melalui Aplikasi RPT, Media Komunikasi, atau Media Publikasi.<br><br>
          <strong class="fw-bold">Z. KEBATALAN</strong><br><br>

          Kebatalan atau ketidakberlakuan atas suatu ketentuan dalam Syarat dan Ketentuan ini karena diberlakukannya suatu peraturan perundang-undangan atau alasan lainnya tidak akan secara otomatis membatalkan keberlakuan atas ketentuan-ketentuan lainnya dalam Syarat dan Ketentuan ini. Dalam hal terdapat suatu peraturan perundang-undangan yang menjadikan salah satu ketentuan dalam Syarat dan Ketentuan ini menjadi batal atau tidak dapat diterapkan maka Anda dan Kami sepakat untuk menganggap bahwa ketentuan tersebut secara otomatis telah disesuaikan dengan ketentuan dari peraturan perundang-undangan tersebut.<br><br>
          <strong class="fw-bold">AA. BAHASA</strong><br><br>

          Jika Syarat dan Ketentuan ini dibuat dalam Bahasa Indonesia dan Bahasa Inggris maka versi Bahasa Inggris hanya akan berlaku sebagai terjemahan dari versi Bahasa Indonesia untuk memudahkan pemahaman Syarat dan Ketentuan ini bagi pihak manapun yang membutuhkannya. Jika terdapat perbedaan antara kedua versi maka versi Bahasa Indonesia yang akan berlaku dan versi Bahasa Inggris akan dianggap telah disesuaikan dengan versi Bahasa Indonesia secara otomatis.<br><br>
          <strong class="fw-bold">BB. TANGGAL BERLAKU</strong><br><br>

          Ketentuan-ketentuan dalam Syarat dan Ketentuan ini merupakan versi yang Kami berlakukan secara efektif sejak tanggal 9 Januari 2025. <br><br>
          <strong class="fw-bold">Terima Term & Condition</strong>
          <br><br>
        </p>
        <form @submit.prevent="agree()">
          <table>
            <thead>
              <tr>
                <td style="width: 30px"><input type="checkbox" id="agree" required></td>
                <td><label for="agree">Saya setuju dengan semua syarat dan ketentuan yang tercantum di atas</label></td>
              </tr>
            </thead>
          </table>       
          
          <button type="submit" class="btn btn-primary w-100 mt-2 mb-5">lanjut</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  name: 'HomeView',
  mounted(){    
    // Verifikasi token dan user ID
    const token = Cookies.get('auth_token');
    const customerId = Cookies.get('customer_id');
    console.log(token + ' ' + customerId);
    
    if (token != undefined || customerId  != undefined) {
      this.$router.push("/main");
    } 
  },
  methods: {
    openTermCondition() {
      document.querySelector('.term-condition').classList.add('d-block')
      document.querySelector('.term-condition').classList.remove('d-none')
    },
    closeTermCondition() {
      document.querySelector('.term-condition').classList.remove('d-block')
      document.querySelector('.term-condition').classList.add('d-none')
    },
    agree(){
      this.$router.push("/regis")
    }
  }
}
</script>

<style>
.term-condition {
  z-index: 999;
  position: fixed;
  top: 0;left: 0;right: 0;bottom: 0;
  background-color: white;
  overflow-y: scroll
}

.fs-term-condition {
  font-size: 13px;
}
</style>

