<template>
  <div class="banner position-relative">
    <div class="container p-4">
      <div class="d-flex justify-content-between align-items-center">
        <router-link
          to="/main"
          class="text-white"
          style="text-shadow: 0 0 4px rgba(0, 0, 0, 0.3); text-decoration: none"
          ><i class="bi bi-arrow-left-circle-fill me-2"></i>Kembali</router-link
        >
      </div>
    </div>
  </div>
  <div class="gap-fix p-3">
    <h1 class="mb-3">History</h1>
    <div
      class="flex items-center bg-white shadow-lg rounded-xl p-2 w-96 d-flex justify-content-center align-items-center kotak mb-3"
    >
      <!-- Icon Section -->
      <div
        class="flex items-center justify-center w-12 h-12 bg-blue-100 rounded-lg"
      >
        <div class="pem">
          <i :class="iconClass"></i>
        </div>
      </div>

      <!-- Transaction Details -->
      <div class="transaction-details">
        <h3 class="mb-0">Cash-in</h3>
        <p class="transaction-id mb-0">Transaction ID</p>
        <p class="transaction-id mb-0">564925374920</p>
      </div>

      <!-- Amount & Status -->
      <div class="text-right text-end">
        <p class="text-xs text-gray-400 mb-0">17 Sep 2023</p>
        <p class="text-xs text-gray-400 mb-0">10:34 AM</p>
      </div>
    </div>
    <div
      class="flex items-center bg-white shadow-lg rounded-xl p-2 w-96 d-flex justify-content-center align-items-center kotak"
    >
      <!-- Icon Section -->
      <div
        class="flex items-center justify-center w-12 h-12 bg-blue-100 rounded-lg"
      >
        <div class="pem">
          <i class="fa-solid fa-circle-minus text-danger"></i>
        </div>
      </div>

      <!-- Transaction Details -->
      <div class="transaction-details">
        <h3 class="mb-0">Cash-in</h3>
        <p class="transaction-id mb-0">Transaction ID</p>
        <p class="transaction-id mb-0">564925374920</p>
      </div>

      <!-- Amount & Status -->
      <div class="text-right text-end">
        <p class="text-xs text-gray-400 mb-0">17 Sep 2023</p>
        <p class="text-xs text-gray-400 mb-0">10:34 AM</p>
      </div>
    </div>

    <!-- Tombol untuk mengubah status -->
    <!-- <button @click="toggleStatus" class="btn btn-primary mt-3">
        Toggle Status
      </button> -->
  </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
  setup() {
    const status = ref("success"); // Status transaksi (bisa "success" atau "failed")

    const iconClass = computed(() =>
      status.value === "success"
        ? "fa-solid fa-circle-plus text-success"
        : "fa-solid fa-circle-plus text-danger"
    );

    const toggleStatus = () => {
      status.value = status.value === "success" ? "failed" : "success";
    };

    return {
      status,
      iconClass,
      toggleStatus,
    };
  },
};
</script>

<style>
.banner {
  background-image: url(../assets/bg-main.jpg);
  background-size: 110%;
  background-position: center;
  width: 100%;
  height: 80px;
  border-radius: 0 0 15px 15px;
}

.gap-fix {
  min-height: calc(100dvh - 80px);
}
.transaction-details {
  margin-right: 10px;
  margin: 10px; /* Gunakan 0 bukan none */
  padding: 0; /* Gunakan 0 bukan none */
  width: 180px;
}
.text-xs,
.transaction-id {
  font-size: 12px;
}
.kotak {
  border-radius: 10px;
}
.pem {
  height: 100%;
  width: 30px;
  font-size: 25px;
}
</style>
