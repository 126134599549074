<template>
    <div class="app">
      <!-- Tombol 'Back' -->
      <router-link
        class="back bg-blur border-2 border-light shadow-custom rounded"
        id="backButton"
        to="/minning"
      >
        <svg
          width="50px"
          height="30px"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#ffffff"
            d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
          />
          <path
            fill="#ffffff"
            d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
          />
        </svg>
      </router-link>
  
      <!-- Video Background -->
      <!-- <video ref="bgVideo" src="../../assets/minning/matrix.mp4" autoplay loop muted></video> -->
  
      <!-- Bagian bawah yang menampilkan info Energi & RPT, serta tombol Start/Stop Mining -->
      <div class="bottom-section">
        <div class="info-container">
          <!-- Energi -->
          <div class="info-bar">
            UV:
            <span id="energy">{{ Math.max(Math.floor(energy), 0) }}</span>
            <div class="progress-bar">
              <div
                id="energy-progress"
                class="progress-fill"
                :style="{ width: energyBarWidth }"
              ></div>
            </div>
          </div>
  
          <!-- RPT -->
          <div class="info-bar">
            TV: <span id="rpt">{{ Math.floor(tv) }}</span>
          </div>
        </div>
  
        <!-- Tombol Start/Stop Mining -->
        <button class="btn" id="toggleButton" @click="toggleMining">
          {{ isMining ? 'Stop Mining' : 'Start Mining' }}
        </button>
      </div>
  
      <!-- Audio loop -->
      <audio ref="bgAudio" src="../../assets/minning/bin.mp3" loop></audio>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Cookies from 'js-cookie';
  
  export default {
    name: 'MiningClaim',
    data() {
      return {
        // State energi dan tv
        energy: 0,
        tv: 0,
  
        // Apakah sedang mining?
        isMining: false,
  
        // Nilai pengurangan energi & penambahan RPT
        // misalnya energi 1000 habis dalam 3 jam => per detik = 1000 / (3 jam = 3*3600)
        energyReductionRate: 1000 / (3 * 60 * 60),
        // misalnya RPT 100000 tercapai dalam 3 jam => per detik = 100000 / (3*3600)
        rptGainRate: 100000 / (3 * 60 * 60),
  
        // Interval untuk menambang & mengupdate data ke server
        miningInterval: null,
        updateInterval: null,
      };
    },
    computed: {
      /**
       * Lebar progress bar energi
       */
      energyBarWidth() {
        const percentage = (this.energy / 1000) * 100;
        return percentage > 100 ? '100%' : `${percentage}%`;
      },
    },
    mounted() {
      // Setelah komponen dipasang, ambil data user (energi & RPT) dari server
      this.fetchUserData();
    },
    methods: {
      /**
       * Mengambil token & customerId dari cookies (sudah di-set sebelumnya).
       */
      getAccessToken() {
        return Cookies.get('auth_token');
      },
      getcustomerId() {
        return Cookies.get('customer_id');
      },
  
      /**
       * Memuat data user (energi & RPT) pertama kali
       */
      async fetchUserData() {
        const accessToken = this.getAccessToken();
        const customerId = this.getcustomerId();
  
        if (!accessToken || !customerId) {
          console.error("auth_token atau customer_id tidak ditemukan di cookies!");
          return;
        }
  
        try {
          const response = await axios.get(
            `https://api.tokoku.org/public/api/customers/${customerId}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
  
          this.energy = response.data.receive_uv_details.point || 0;
          this.tv = response.data.receive_tv_details.point || 0;
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      },
  
      /**
       * Update data energi & RPT di server
       */
      async updateServerData() {
        const accessToken = this.getAccessToken();
        const customerId = this.getcustomerId();
  
        if (!accessToken || !customerId) return;
  
        try {
          // Update energi
          await axios.patch(
            `https://api.tokoku.org/public/api/receive-uv-details/${customerId}/update-point`,
            { point: Math.max(Math.floor(this.energy), 0) },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
  
          // Update RPT
          await axios.patch(
            `https://api.tokoku.org/public/api/receive-tv-details/${customerId}/update-point`,
            { point: Math.floor(this.tv) },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
        } catch (error) {
          console.error('Error updating server data:', error);
        }
      },
  
      /**
       * Menjalankan proses mining:
       * - Mainkan audio
       * - Set interval penambahan RPT & pengurangan energi
       * - Set interval update ke server
       */
      startMining() {
        // Mainkan audio
        const audio = this.$refs.bgAudio;
        if (audio) {
        //   audio.play();
        }
  
        this.isMining = true;
  
        // Tiap 1 detik, kurangi energi & tambah RPT
        this.miningInterval = setInterval(() => {
          if (this.energy > 0) {
            this.energy -= this.energyReductionRate;
            this.tv += this.rptGainRate;
          } else {
            // Energi sudah habis, hentikan mining
            this.stopMining();
          }
        }, 1000);
  
        // Update ke server setiap 30 detik
        this.updateInterval = setInterval(() => {
          this.updateServerData();
        }, 30000);
      },
  
      /**
       * Berhenti mining
       */
      stopMining() {
        this.updateServerData();
        this.isMining = false;
        // const audio = this.$refs.bgAudio;
        // if (audio) {
        //   audio.pause();
        // }
  
        clearInterval(this.miningInterval);
        clearInterval(this.updateInterval);
        this.miningInterval = null;
        this.updateInterval = null;
  
        // Optional: Update data sekali lagi ke server
        this.updateServerData();
      },
  
      /**
       * Toggle Start/Stop mining
       */
      toggleMining() {
        if (this.isMining) {
          this.stopMining();
        } else {
          this.startMining();
        }
      },
  
      /**
       * Kembali ke halaman utama (atau route lain).
       * Di sini meniru perilaku "window.location.href".
       * Anda bisa menggantinya dengan this.$router.push("/some-route") jika pakai Vue Router.
       */
      goBack() {
        const accessToken = this.getAccessToken();
        const customerId = this.getcustomerId();
        window.location.href = `index.html?accessToken=${accessToken}&customerId=${customerId}`;
      },
    },
  };
  </script>
  
  <style scoped>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
    color: white;
    background-color: black;
    overflow: hidden;
  }
  
  /* Kontainer utama */
  .app {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    background-image: url(../../assets/code.gif);
    background-position: center;
    background-size: cover;
    position: relative;
  }
  
  /* Video background */
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
  
  /* Bagian bawah (menampilkan bar info & tombol) */
  .bottom-section {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #dddddd;
  }
  
  /* Info container (energi & RPT) */
  .info-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  
  .info-bar {
    display: flex;
    align-items: center;
    color: black;
    font-weight: bold;
    gap: 5px;
    width: 45%;
  }
  
  /* Progress bar energi */
  .progress-bar {
    flex: 1;
    height: 10px;
    background-color: #c0c0c0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
  
  .progress-fill {
    height: 100%;
    width: 0%;
    background-color: #1f7a1f;
    transition: width 0.5s ease;
  }
  
  /* Tombol Start/Stop Mining */
  .btn {
    width: 120px;
    height: 30px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    background-color: #1f7a1f;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s;
  }
  
  .btn:hover {
    background-color: #155a15;
  }
  
  /* Tombol back (posisi absolute di atas) */
  .back {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 3; /* Pastikan di atas video & elemen lain */
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(15px);
    border: none !important;
    border-radius: 10px;
    cursor: pointer;
    padding: 5px;
  }
  </style>
  