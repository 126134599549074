<template>
  <div class="banner position-relative">
    <div class="container p-4">
      <div class="d-flex justify-content-between align-items-center">
        <router-link to="/main" class="text-white" style="text-shadow: 0 0 4px rgba(0,0,0, .3);text-decoration: none;"><i class="bi bi-arrow-left-circle-fill me-2"></i>Kembali</router-link>
      </div>
    </div>
  </div>
  <div class="gap-fix p-3">
    <h1 class="mb-3">Kirim Pesan</h1>
    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label">Email address</label>
      <input type="email" class="form-control border-secondary" id="exampleFormControlInput1" placeholder="name@example.com">
    </div>
    <div class="mb-3">
      <label for="exampleFormControlTextarea1" class="form-label">Text</label>
      <textarea class="form-control border-secondary" id="exampleFormControlTextarea1" rows="6"></textarea>
      <button class="btn btn-primary w-100 mt-3">Submit</button>
    </div>
  </div>
</template>

<style>
  .banner {
    background-image: url(../assets/bg-main.jpg);
    background-size: 110%;
    background-position: center;
    width: 100%;
    height: 80px;
    border-radius: 0 0 15px 15px;
  }

  .gap-fix  {
    min-height: calc(100dvh - 80px);
  }
</style>
