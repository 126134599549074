<template>
    <div class="game-dashboard">
      <!-- Tombol back -->
      <router-link
        class="back bg-blur border-2 border-light shadow-custom rounded"
        id="backButton"
        to="/minning"
      >
        <svg
          width="50px"
          height="30px"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#ffffff"
            d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
          />
          <path
            fill="#ffffff"
            d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
          />
        </svg>
      </router-link>
  
      <!-- Level "badge" di sisi kiri -->
      <div class="kolam">
        <!-- Menampilkan badge-level (0-4) -->
        <h4
          v-for="(badge, index) in badgeLabels"
          :key="index"
          class="badge-level"
          :class="{ 'active-level': currentBadge === index }"
        >
          {{ badge }}
        </h4>
      </div>
  
      <!-- Container utama -->
      <div class="dashboard-claimBoard pt-5" style="overflow-y: scroll;">
        <div class="wrapper-claimboard">
          <!-- Bagian detail token -->
          <div class="detailToken">
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <h1>Claim Saldo</h1>
            <!-- Teks RPT -->
            <p><span class="rpt-coin">{{ displayRpt }}</span> RPT</p>
  
            <!-- Tombol claim -->
            <button
              class="claim-btn"
              :class="{ 'disable-btn': !canClaim }"
              @click="claimToken"
              :disabled="!canClaim"
            >
              {{ claimBtnText }}
            </button>
          </div>
  
          <!-- Bagian list user yang berhasil claim -->
          <div class="wrapper-board">
            <h2>User Yang Berhasil Claim</h2>
            <div class="claimBoard pb-5">
              <div class="wrappertable" id="claimTable">
                <div class="headtable">
                  <div>Username</div>
                  <div>Token Amount</div>
                </div>
                <!-- Contoh data statis (Anda bisa ganti dari server) -->
                <div id="claimTableBody" style="height: 200px;">
                  <div class="wraperData" v-for="item in win" :key="item.id">
                    <div class="itemData">{{ item.name }}</div>
                    <div class="itemData">{{ item.reward }}</div>
                  </div>
                  <!-- Dst. -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Cookies from "js-cookie";
  import axios from "axios";
  
  export default {
    name: "ClaimToken",
    data() {
      return {
        // Data user
        rpt: 0,
        level: 0,
        win: [],
        winInfo: null,
  
        // Daftar label badge
        badgeLabels: ["1 jt", "3 jt", "6 jt", "12 jt", "24 jt"],
        // Indeks badge saat ini
        currentBadge: 0,
  
        // Tombol claim
        claimBtnText: "Belum Bisa Claim",
        canClaim: false,
      };
    },
    computed: {
      /**
       * Format RPT dengan spasi pemisah ribuan, agar tampilan selaras
       * dengan res.rpt.toString().replace(...) yang di kode asli
       */
      displayRpt() {
        if (!this.rpt) return 0;
        // Menyisipkan spasi pemisah ribuan
        return this.rpt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
    },
    mounted() {
      this.fetchUserData();
    },
    methods: {
      /**
       * Mengambil token & customerId dari cookies
       */
      withdrawal(){
        axios.post("https://api.tokoku.org/public/api/withdrawal")
        .then(res => {
          console.log(res.data);
          this.win = res.data.selected_users
          this.winInfo = res.data
        })
      },
      getAccessToken() {
        return Cookies.get("auth_token");
      },
      getcustomerId() {
        return Cookies.get("customer_id");
      },
  
      /**
       * Memuat data user (termasuk RPT dan level) dari server,
       * lalu menyesuaikan tampilan badge & tombol claim
       */
      async fetchUserData() {
        const accessToken = this.getAccessToken();
        const customerId = this.getcustomerId();
  
        if (!accessToken || !customerId) {
          console.error("auth_token atau customer_id tidak ditemukan di cookies!");
          return;
        }
  
        try {
          const response = await axios.get(
            `https://api.tokoku.org/public/api/customers/${customerId}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
            }
          );
  
          const data = response.data;
          console.log("User data:", data);
  
          // Set RPT dan level
          this.rpt = data.rpt || 0;
          this.level = data.level || 0;
  
          // Tentukan badge active
          this.currentBadge = this.level;
  
          // Cek apakah bisa claim => Sesuaikan dengan logic di kode asli
          this.setupClaimButton();
        } catch (error) {
          console.error("Fetch error:", error);
        }
      },
  
      /**
       * Mengatur tampilan & status tombol claim
       * sesuai logic level & minimal RPT
       */
      setupClaimButton() {
        // Level => minimal RPT
        let minRptNeeded = 0;
  
        if (this.level === 0) {
          minRptNeeded = 1000000; // 1 jt
        } else if (this.level === 1) {
          minRptNeeded = 3000000; // 3 jt
        } else if (this.level === 2) {
          minRptNeeded = 6000000; // 6 jt
        } else if (this.level === 3) {
          minRptNeeded = 12000000; // 12 jt
        } else if (this.level === 4) {
          minRptNeeded = 2400000; // (Asli kodenya 2400000, tp label "24 jt"? 
                                  // Ada mismatch, tetap pakai 2.4jt agar sesuai code.
        }
  
        if (this.rpt >= minRptNeeded) {
          this.claimBtnText = "Claim Saldo";
          this.canClaim = true;
        } else {
          this.claimBtnText = "Belum Bisa Claim";
          this.canClaim = false;
        }
      },
  
      /**
       * Klik tombol claim
       */
      claimToken() {
        if (!this.canClaim) {
          console.log("Belum bisa claim");
          return;
        }
        // alert("Claim Saldo!");
        this.withdrawal()
        // Di sini Anda bisa panggil API "claim" dsb.
      },
  
      /**
       * Tombol "back"
       */
      goBack() {
        const accessToken = this.getAccessToken();
        const customerId = this.getcustomerId();
        // Kembalikan user ke index.html, misal:
        window.location.href = `index.html?accessToken=${accessToken}&customerId=${customerId}`;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Style persis dari HTML asli */
  
.game-dashboard {
  width: calc(100% + 5px);
  height: calc(100dvh + 5px);
  transform: translate(-5px, -5px);
  overflow-y: hidden;
  overflow-x: hidden;
  background-image: url(../../assets/img/game.jpg);
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 20px;
  display: flex;
  align-items: center;
}
  body {
    height: 100vh;
    width: 100vw;
    position: relative;
  }
  
  /* Tombol "Back" di kiri atas */
  .back {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(15px);
    border: none !important;
    border-radius: 10px;
    cursor: pointer;
    padding: 5px;
  }
  
  /* Bagian Kolam & badge-level */
  .kolam {
    position: fixed;
    top: 40px;
    width: 100px;
    z-index: 999;
    padding: 20px;
  }
  
  .badge-level {
    width: 80px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #414141;
    color: white;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .active-level {
    background-color: #479af8 !important;
  }
  
  /* Dashboard utamanya */
  .dashboard-claimBoard {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Bagian wrapper-claimboard */
  .wrapper-claimboard {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 70px;
    justify-content: center;
    align-items: center;
  }
  
  /* Detail token di atas (judul, RPT, tombol) */
  .detailToken {
    text-align: center;
    font-size: 16px;
    margin-bottom: 30px;
    font-family: "Lato", sans-serif;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: white;
  }
  
  .detailToken .claim-btn {
    background-color: #ff8c00;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    font-weight: 800;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .disable-btn {
    background-color: #414141 !important;
  }
  
  /* Wrapper board di bawah (list user) */
  .wrapper-board {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 80%;
    gap: 50px;
    flex-direction: column;
    font-family: "Lato", sans-serif;
    color: white;
  }
  
  /* Box tabel claim */
  .claimBoard {
    background-color: transparent; /* disesuaikan */
  }
  
  /* Tabel klaim (static data) */
  .wrappertable {
    flex-direction: column;
    display: flex;
    gap: 10px;
    justify-content: start;
    align-items: center;
  }
  
  .headtable {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height: 50px;
    gap: 20px;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  
  #claimTableBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: scroll;
    max-height: 650px;
    width: 100%;
  }
  
  /* Baris data */
  .wraperData {
    gap: 20px;
    width: 100%;
    height: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    cursor: pointer;
  }
  
  .itemData {
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(15px);
    padding: 12px 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  </style>
  