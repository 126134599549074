<template>
  <div class="pulsa position-relative">
    <div class="container p-4">
      <div class="d-flex justify-content-between align-items-center">
        <router-link to="/main" class="text-white" style="text-shadow: 0 0 4px rgba(0,0,0, .3);text-decoration: none;"><i class="bi bi-arrow-left-circle-fill me-2"></i>Kembali</router-link>
      </div>
    </div>
  </div>
  <div class="container gap-fix-1 p-4">
    <h3>Transfer Saldo.</h3>
    <form @submit.prevent="transferSaldo">
      <div class="mb-3">
        <label for="isi" class="form-label">username Tujuan.</label>
        <input type="text" class="form-control" id="isi" v-model="usernameReceive" placeholder="Masukan username Tujuan">
      </div>
      <div class="card mb-3 p-3 border-info bg-info-subtle position-relative">
        <small class="text-info">Sumber Dana</small>
        <h6 class="m-0 p-0">Saldo Cash Saya.</h6>
        <h3 class="m-0 p-0">{{ formatRupiah(saldo) }}</h3>
        <i class="bi bi-cash-stack position-absolute text-info" style="bottom: -10px;right: 10px;font-size: 64px;"></i>
      </div>
      <div class="mb-3">
        <label for="isi" class="form-label">Nominal Transfer.</label>
        <input type="tel" class="form-control" id="isi" v-model="transferAmount" placeholder="Masukan Nominal">
      </div>
      <button type="submit" class="btn btn-primary w-100 btn-lg">Kirim</button>
    </form>
  </div>
</template>


<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

export default {
  name: 'TFView',
  data() {
    return {
      saldo: 0,
      pin: null,
      token: "",
      customerId: "",
      name: "",
      usernameReceive: "",
      transferAmount: 0,
    };
  },
  mounted() {
    const token = Cookies.get('auth_token');
    const customerId = Cookies.get('customer_id');

    if (!token || !customerId) {
      this.token = "";
      this.customerId = "";
      this.$router.push("/");
    } else {
      this.customerId = customerId;
      this.token = token;
      axios
        .get("https://api.tokoku.org/public/api/customers/" + customerId, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.pin = response.data.pin;     
          this.name = response.data.name;
          this.saldo = 0;
        });
    }
  },
  methods: {
    transferSaldo(){
      if(this.saldo >= this.transferAmount){
        axios.get(`https://api.tokoku.org/public/api/user-username/${this.usernameReceive}`, {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            })
              .then((res) => {
                axios.patch(`https://api.tokoku.org/public/api/customers/${res.data.id}/saldo`, {
                  saldo: res.data.saldo + this.transferAmount
                }, {
                  headers: {
                    Authorization: `Bearer ${this.token}`,
                  },
                })
                  .then(() => {
                    axios.patch(`https://api.tokoku.org/public/api/customers/${this.customerId}/saldo`, {
                      saldo: this.saldo - this.transferAmount
                    }, {
                      headers: {
                        Authorization: `Bearer ${this.token}`,
                      },
                    })
                      .then(() => {
                        this.$router.push("/tf")
                        this.transferAmount = 0
                        this.usernameReceive = ""
                      })
                  })
              })
      }else {
        Swal.fire({
          icon: 'error',
          title: 'Saldo Tidak Cukup!'
        })
      }
    },
    formatRupiah(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value); 
    },
  },
};
</script>

<style>
  .pulsa {
    background-image: url(../../assets/bg.jpg);
    background-size: 110%;
    background-position: center;
    width: 100%;
    height: 80px;
    border-radius: 0 0 15px 15px;
  }
</style>
