<template>
  <div class="login bg-white p-3 pt-5">
    <h3 class="fw-bold text-center">Welcome to RPT App</h3>
    <small class="d-block text-center">Login untuk masuk ke halaman utama</small>
    <img src="@/assets/coin.png" alt="earth" width="100%" style="transform: scaleX(-1);">
    <form @submit.prevent="loginUser">
      <div class="mb-3">
        <label for="email" class="form-label">Email Anda</label>
        <input type="email" class="form-control" id="email" v-model="form.email">
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Password</label>
        <div class="input-group">
          <input id="password-field" :type="passwordFieldType" class="form-control" v-model="form.password">
          <span class="input-group-text text-secondary toggle-password" @click="togglePasswordVisibility">
            <i :class="passwordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
          </span>
        </div>
      </div>
      <button type="submit" class="btn w-100 btn-lg btn-primary">Submit</button>
      <router-link to="/" class="btn w-100 btn-lg btn-outline-secondary mt-2">Kembali</router-link>
    </form>
    <p class="mt-3">Belum Punya akun? <router-link to="/">Daftar terlebih dahulu</router-link></p>
  </div>
  <div class="footer py-5 px-3 bg-primary text-white">
    <h6>PT Indonesia Akita Skye</h6>
    <a href="https://company.tokoku.org" class="text-white text-decoration-none fs-6 my-3 d-flex">About us</a>
    <span>Contact Us :</span><br>
    <a href="mailto:skye.operator@gmail.com" class="text-white text-decoration-none fs-6 d-flex">skye.operator@gmail.com</a>
    <span>+62 857-7008-5997</span>
    <br>
    <br>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.7950454296074!2d106.7826872!3d-6.2906471999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f13bf4da5429%3A0xe8c29d298a2fd6db!2sPalma%20Tower!5e0!3m2!1sid!2sid!4v1733886576126!5m2!1sid!2sid" width="100%" height="250" style="border:0;" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <small>TB Simatupang, JL. RA Kartini II-S Kav. 6, RT.6/RW.14, Pd. Pinang, Kec. Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12310</small>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

export default {
  name: 'LoginView',
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      passwordFieldType: 'password',
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    loginUser() {
      axios.post('https://api.tokoku.org/public/api/login', this.form)
        .then(response => {
          // Simpan token dan ID user ke cookies
          Cookies.set('auth_token', response.data.token, { expires: 7 }); // Token valid 7 hari
          Cookies.set('customer_id', response.data.customer_id, { expires: 7 });
  
          // Tampilkan pesan sukses dan arahkan ke dashboard
          Swal.fire({
            title: 'Login Successful!',
            text: 'You are now logged in.',
            icon: 'success',
            confirmButtonText: 'Go to Dashboard',
          }).then(() => {
            this.$router.push('/main');
          });
        })
        .catch(() => {
          Swal.fire({
            title: 'Login Failed',
            text: 'Invalid email or password.',
            icon: 'error',
            confirmButtonText: 'Try Again',
          });
        })
    },
  },
};
</script>

<style>
  .login {
    width: 100%;
    min-height: 100dvh;
  }

  .field-icon {
    float: right;
    margin-left: -25px;
    margin-top: -25px;
    position: relative;
    z-index: 2;
    padding-right: 30px;
  }

  .container {
    padding-top: 50px;
    margin: auto;
  }
</style>
