<template>
  <div class="pulsa position-relative">
    <div class="container p-4">
      <div class="d-flex justify-content-between align-items-center">
        <router-link to="/main" class="text-white" style="text-shadow: 0 0 4px rgba(0,0,0, .3); text-decoration: none;">
          <i class="bi bi-arrow-left-circle-fill me-2"></i>Kembali
        </router-link>
      </div>
    </div>
  </div>

  <div class="container gap-fix-1 p-4">
    <h3>Isi PLN - Prabayar.</h3>
    <form @submit.prevent="handleSubmit">
      <div class="mb-3">
        <label for="isi" class="form-label">No. Meteran</label>
        <input 
          type="tel" 
          id="nomorInput" 
          class="form-control" 
          placeholder="Masukkan nomor meteran"
          v-model="custNumber" 
          required 
        />
      </div>

      <button 
        type="submit" 
        class="btn btn-primary w-100" 
        :disabled="!selectedProduct || !custNumber"
      >
        Kirim
      </button>
    </form>

    <hr class="my-3">
    <div class="d-flex flex-wrap justify-content-between">
      <div 
        class="mb-3"
        style="width: 100%;" 
        v-for="(product) in products.slice(0, 4)" 
        :key="product.buyer_sku_code"
      >
        <div 
          class="card p-3"
          :class="{ 'border-primary': product === selectedProduct }" 
          @click="selectProduct(product)"
        >
          <small>{{ product.product_name }}</small>
          <h5 class="m-0 p-0">{{ formatCurrency(product.price) }}</h5>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-float floating" v-if="buy">
    <div class="box w-100 p-4 bg-light">
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="text-muted m-0">Konfirmasi Transaksi</h3>
        <i class="bi bi-x-circle fs-1 text-secondary" @click="closeFloat"></i>
      </div>
      <hr>      
      <h3>Isi PLN Prabayar<span v-if="cekStatus != 'Pending'" class="fs-5"><br/>{{ customer.sn }}</span></h3>
      <small class="text-center">Topup {{ buy.product_name }} untuk nomor {{ custNumber }}</small>

      <h6 class="fw-bold d-flex justify-content-between bg-primary-subtle text-primary rounded py-4 px-3 mt-3">
        <span>Total Biaya</span>{{ formatCurrency(buy.price) }}
      </h6>

      <div class="card mb-3 p-3 border-primary bg-primary-subtle position-relative">
        <small class="text-primary">Sumber Dana</small>
        <h6>Saldo Cash Saya</h6>
        <h3>Rp 100.000</h3>
        <i class="bi bi-cash-stack position-absolute text-primary" style="bottom: -10px; right: 10px; font-size: 64px;"></i>
      </div>

      <button 
        class="btn btn-primary w-100" 
        :disabled="isLoading" 
        @click="confirmPurchase"
      >
        {{ isLoading ? 'Memproses...' : 'Beli' }}
      </button>
    </div>
  </div>

  <div class="bg-float floating-success" v-if="buy">
    <div class="box w-100 p-4 bg-light">
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="text-muted m-0">Status Transaksi</h3>
        <i class="bi bi-x-circle fs-1 text-secondary" @click="closeFloat"></i>
      </div>
      <hr>
      <i class="bi bi-check-circle-fill d-block text-center text-success" v-if="success" style="font-size: 90px;"></i>
      <i class="bi bi-exclamation-circle-fill d-block text-center text-warning" v-if="!success" style="font-size: 90px;"></i>
      <h4 class="text-center">{{ success? "Selesai" : "Pending" }}</h4>
      <h3 class="text-center">Isi PLN Prabayar<span v-if="cekStatus != 'Pending'" class="fs-5"><br/>{{ customer.sn }}</span></h3>
      <small class="text-center d-block">Beli {{ buy.product_name }} untuk nomor {{ custNumber }}</small>

      <h6 class="fw-bold d-flex justify-content-between bg-primary-subtle mb-3 text-primary rounded py-4 px-3 mt-3">
        <span>Token Anda : {{ token }}</span>
      </h6>
      <h6 class="fw-bold d-flex justify-content-between bg-primary-subtle text-primary rounded py-4 px-3 mt-3">
        <span>Total Biaya</span>{{ formatCurrency(buy.price) }}
      </h6>

      <button 
       v-if="!success"
        class="btn btn-primary w-100" 
        @click="confirmPurchase"
      >
        Cek Status
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PLNView",
  data() {
    return {
      products: [],
      selectedProduct: null,
      custNumber: "",
      buy: null,
      refId: "",
      success: false,       
      cekStatus: "",
      token: "",
      customer: {
        sn: ""
      },
      isLoading: true
    };
  },
  mounted() {
    this.getProducts();
    
    setInterval(() => {
      if(this.cekStatus == "Pending") {
          const data = {
            buyer_sku_code: "plncek",
            customer_no: this.custNumber,
            ref_id: this.refId
          };        

        axios.post("https://api.tokoku.org/public/api/digiflazz/buy-product", data)
         .then((response) => {
            console.log(response.data.data);
            if(response.data.data.status == "Pending"){
              this.cekStatus = response.data.data.status
              this.isLoading = true
            }else {
              this.refId = ""
              this.cekStatus = ""
              this.customer = response.data.data
              this.isLoading = false
            }
          })
         .catch((error) => {
            const errorData = error.response.data;
            console.error(errorData);
          });
      }
    }, 5000)
  },
  methods: {
    generateRefId() {
      const now = new Date();
      const dateStr = `${now.getFullYear()}${(now.getMonth() + 1)
        .toString()
        .padStart(2, "0")}${now.getDate().toString().padStart(2, "0")}`;
      const timeStr = `${now.getHours().toString().padStart(2, "0")}${now.getMinutes().toString().padStart(2, "0")}`;
      const rand =  Math.floor(Math.random() * (10 - 1 + 1)) + 1;
      return `${this.custNumber}${dateStr}${timeStr}${rand}`;
    },
    openFloat() {
      document.querySelector(".floating").classList.add("on-bg-float");
    },
    closeFloat() {
      this.buy = null;
      window.location.reload(); // Sementara
    },
    formatCurrency(value) {
      if (value === undefined || value === null) {
        return ''; // atau nilai default yang Anda inginkan
      }
      return value.toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
    },
    getProducts() {
      axios
        .post("https://api.tokoku.org/public/api/digiflazz/price-list")
        .then((res) => {
          console.log(res.data.data);          
          this.products = res.data.data.filter(
            (item) => item.category === "PLN" && item.brand === "PLN"
          );
        })
        .catch((err) => console.error(err));
    },
    selectProduct(product) {
      this.selectedProduct = product;
      this.buy = product;
    },
    async confirmPurchase() {
      if(this.refId == ""){
        this.refId = this.generateRefId();
      }
      const data = {
        buyer_sku_code: this.buy.buyer_sku_code,
        customer_no: this.custNumber,
        ref_id: this.refId
      };

      this.isLoading = true;

      try {
        const response = await axios.post("https://api.tokoku.org/public/api/digiflazz/buy-product", data);
        // alert("Transaksi Berhasil!");
        console.log(response.data);
        this.token = response.data.data.sn;
        // this.closeFloat();
        document.querySelector(".floating").classList.remove("on-bg-float");
        document.querySelector(".floating-success").classList.add("on-bg-float-success");
        if(response.data.data.status !== "Pending"){
          this.refId = ""
          this.success = true;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleSubmit() {
      if (this.selectedProduct && this.custNumber) {
        this.openFloat();

        if(this.refId == ""){
          this.refId = this.generateRefId();
        }

        const data = {
          buyer_sku_code: "plncek",
          customer_no: this.custNumber,
          ref_id: this.refId
        };

        axios.post("https://api.tokoku.org/public/api/digiflazz/buy-product", data)
         .then((response) => {
            // console.log(response.data.data);
            if(response.data.data.status == "Pending"){
              this.cekStatus = response.data.data.status
              this.isLoading = true
            }else {
              this.refId = ""
              this.cekStatus = ""
              this.isLoading = false
            }
          })
         .catch((error) => {
            const errorData = error.response.data;
            console.error(errorData);
          });
      }
    }
  }
};
</script>

<style>
.pulsa {
  background-image: url(../../assets/bg.jpg);
  background-size: 110%;
  background-position: center;
  height: 80px;
  border-radius: 0 0 15px 15px;
}

.card {
  cursor: pointer;
}

.border-primary {
  border: 2px solid #007bff !important;
}

.bg-float {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.on-bg-float {
  display: block;
}

.on-bg-float-success {
  display: block;
}
</style>
