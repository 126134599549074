<template>
  <div class="regis bg-white p-3 pt-5">
    <h3 class="fw-bold">Register</h3>
    <br><br>
    <form @submit.prevent="registerUser">
      <div class="mb-3">
        <label for="name" class="form-label">Nama Anda</label>
        <input type="text" class="form-control" id="name" v-model="form.name">
      </div>
      <div class="mb-3">
        <label for="customerId" class="form-label">Username</label>
        <input type="text" class="form-control" id="userid" v-model="form.userid">
      </div>
      <div class="mb-3">
        <label for="mobile_no" class="form-label">Nomor HP</label>
        <input type="text" class="form-control" id="mobile_no" v-model="form.mobile_no">
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Email Anda</label>
        <div class="d-flex gap-2">
          <input type="email" class="form-control w-75" id="email" v-model="form.email">
          <button type="button" class="btn btn-outline-primary w-25" @click="otpSender()">OTP</button>
        </div>
      </div>
      <div class="mb-3">
        <label for="otp" class="form-label">OTP</label>
        <input type="tel" minlength="6" maxlength="6" class="form-control" id="otp" v-model="form.otp" required>
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Password</label>
        <div class="input-group">
          <input id="password-field" :type="passwordFieldType" class="form-control" v-model="form.password">
          <span class="input-group-text text-secondary toggle-password" @click="togglePasswordVisibility">
            <i :class="passwordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
          </span>
        </div>
      </div>
      <hr>
      <div class="mb-3">
        <label for="refferal_id" class="form-label">Referral code</label>
        <input type="text" class="form-control" id="refferal_id" v-model="form.refferal_id">
      </div>
      <button type="submit" class="btn w-100 btn-lg btn-primary w-100">Submit</button>
      <router-link to="/" class="btn w-100 btn-lg btn-outline-secondary mt-2 w-100">Kembali</router-link>
    </form>
    <p class="mt-3 mb-5">Sudah Punya akun? <router-link to="/login">Login</router-link></p>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

export default {
  name: 'RegisView',
  data() {
    return {
      form: {
        name: '',
        email: '',
        userid: '',
        password: '',
        mobile_no: '',
        refferal_id: '',
        otp: '',
      },
      passwordFieldType: 'password',
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    async registerUser() {
      try {
        let storedOtp = Cookies.get('otp');
        let storedEmail = Cookies.get('otp_email');

        if (this.form.otp == storedOtp && this.form.email == storedEmail) {
          await axios.post('https://api.tokoku.org/public/api/register', this.form);
          
          Swal.fire({
            title: 'Registration Successful!',
            text: 'Your account has been created.',
            icon: 'success',
            confirmButtonText: 'Go to Login',
          }).then(() => {
            this.$router.push('/login');
          });

        } else {
          Swal.fire({
            title: 'OTP Salah',
            text: 'Kode OTP tidak valid atau email tidak sesuai.',
            icon: 'error',
            confirmButtonText: 'Okay',
          });
        }
      } catch (error) {
        if (error.response) {
          Swal.fire({
            title: 'Error!',
            // text: JSON.stringify(error.response.data),
            icon: 'error',
            confirmButtonText: 'Try Again',
          });
        } else {
          Swal.fire({
            title: 'Oops...',
            text: 'Something went wrong. Please try again.',
            icon: 'error',
            confirmButtonText: 'Okay',
          });
        }
      }
    },
    async otpSender() {
      if (!this.form.email) {
        Swal.fire({
          title: 'Email Kosong',
          text: 'Isi Email Terlebih dulu.',
          icon: 'error',
          confirmButtonText: 'Okay',
        });
        return;
      }

      try {
        const response = await axios.post('https://api.tokoku.org/public/api/send-otp', {
          email: this.form.email,
        });

        Cookies.set('otp_email', response.data.otp_email, { expires: 10 / 1440 }); // 10 menit
        Cookies.set('otp', response.data.otp, { expires: 10 / 1440 });

        Swal.fire({
          title: 'Cek Email Anda!',
          text: 'OTP telah dikirim ke email Anda.',
          icon: 'success',
        });

      } catch (error) {
        Swal.fire({
          title: 'Error Mengirim OTP',
          text: error.response?.data?.message || 'Terjadi kesalahan saat mengirim OTP.',
          icon: 'error',
          confirmButtonText: 'Coba Lagi',
        });
      }
    }
  },
};
</script>

<style>
.regis {
  width: 100%;
  min-height: 100vh;
}
</style>
